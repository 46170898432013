import React, { useEffect, useState } from 'react'
import { CAvatar, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react'
import { cilUser, cilAccountLogout } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import PropTypes from 'prop-types'
import avatar from '../../assets/images/avatars/Avatars.png'
import { withRouter } from 'react-router-dom'
import { URL__API } from 'src/axios'
import axios from 'axios'

const AppHeaderDropdown = ({ history }) => {
  const [admin, setAdmin] = useState(null)

  useEffect(() => {
    const fetchMe = async () => {
      const res = await axios.get(`${URL__API}/user/profile/${localStorage.getItem('user_id')}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      setAdmin(res.data)
    }
    fetchMe()
  }, [])

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar src={admin?.avatar ? admin.avatar : avatar} size="md" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem href="/compte">
          <CIcon icon={cilUser} className="me-2" />
          Profile
        </CDropdownItem>
        <CDropdownItem
          onClick={(e) => {
            e.preventDefault()
            localStorage.removeItem('token')
            history.push('/login')
          }}
          href="#"
        >
          <CIcon icon={cilAccountLogout} className="me-2" />
          Se déconnecter
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}
AppHeaderDropdown.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default withRouter(AppHeaderDropdown)
