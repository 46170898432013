import React from 'react'
import { useHistory } from 'react-router-dom'

function Cities() {
  let { location } = useHistory()

  return (
    <div
      style={{
        color: `${location.pathname === '/cities' ? '#2ACC96' : 'white'}`,
      }}
    >
      Villes
    </div>
  )
}

export default Cities
