import { CSpinner } from '@coreui/react'
import React from 'react'

function Loading() {
  return (
    <div className="pt-5 text-center">
      <CSpinner color="primary" grow={true} />
    </div>
  )
}

export default Loading
