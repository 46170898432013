import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import DefaultLayout from 'src/layout/DefaultLayout'
import axios from 'axios'
import { URL__API } from 'src/axios'
import avatar from '../../../assets/images/avatars/Avatars.png'
import { CCard } from '@coreui/react'
import './message.css'
import { format } from 'date-fns'
import { cilX } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import Loading from 'src/components/Loading'

function MessageInfo({ history, match }) {
  const [chats, setChats] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchChats = async () => {
    const res = await axios.get(`${URL__API}/messages/all`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    setLoading(false)
    setChats(
      res.data.filter(
        (chat) =>
          (chat?.sender?.id === match?.params?.receiverId &&
            chat?.receiver?.id === match?.params?.senderId) ||
          (chat?.sender?.id === match?.params?.senderId &&
            chat?.receiver?.id === match?.params?.receiverId),
      ),
    )
  }

  useEffect(() => {
    fetchChats()
  }, [chats, match.params.receiverId, match.params.senderId])

  const deleteOneMsg = async (id) => {
    await axios.delete(`${URL__API}/messages/${id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    fetchChats()
  }

  return (
    <DefaultLayout>
      <CCard
        style={{
          width: '100%',
          height: '73vh',
          overflowY: 'scroll',
        }}
        className="mx-auto pt-5"
      >
        {loading ? (
          <Loading />
        ) : (
          <div
            style={{
              position: 'relative',
            }}
          >
            {chats.map((chat, index) => (
              <div key={index}>
                <div
                  data-spy="scroll"
                  className={` mx-4 my-5 border px-2 ${
                    chat?.sender?.id === match?.params?.receiverId ? 'toright' : 'toleft'
                  }`}
                  style={{
                    borderRadius: '5px',
                    scrollBehavior: 'smooth',
                    // width: '45%',
                    // position: `${chat?.sender?.id === match?.params?.receiverId ? 'absolute' : ''} `,
                    right: '10px',
                    backgroundColor: `${
                      chat?.sender?.id === match?.params?.receiverId ? 'lightgreen' : '#eeeeee'
                    }`,
                  }}
                >
                  <div className="d-flex mt-1 justify-content-between">
                    <div className="d-flex mt-1">
                      <div style={{ width: '1.5rem', height: '1.5rem', textAlign: 'center' }}>
                        <img
                          alt="avatar"
                          style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                          }}
                          src={chat.sender.avatar ? chat.sender.avatar : avatar}
                        />
                      </div>
                      <p className="mx-2" style={{ fontSize: '15px' }}>
                        {chat.sender.firstName + ' ' + chat.sender.lastname}
                      </p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span style={{ textAlign: 'end' }}>
                        <CIcon
                          title="supprimer"
                          icon={cilX}
                          style={{
                            cursor: 'pointer',
                            color: 'red',
                            border: '2px solid red',
                            borderRadius: '100%',
                            // padding: '1px',
                            margin: '9px',
                          }}
                          size="custom"
                          onClick={() => {
                            deleteOneMsg(chat.id)
                          }}
                        />
                      </span>
                      <span className="mt-2" style={{ fontSize: '13px' }}>
                        {format(new Date(chat.date), 'dd/MM/yyyy | HH:mm:ss')}
                      </span>
                    </div>
                  </div>
                  <h3 className="my-1" style={{ fontSize: '16px' }}>
                    {chat?.message}
                  </h3>
                </div>
              </div>
            ))}
          </div>
        )}
      </CCard>
    </DefaultLayout>
  )
}

MessageInfo.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      senderId: PropTypes.string.isRequired,
      receiverId: PropTypes.string.isRequired,
    }),
  }),
}

export default MessageInfo
