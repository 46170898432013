import React, { useState } from 'react'
import DefaultLayout from 'src/layout/DefaultLayout'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { CAlert, CButton, CCol, CForm, CFormInput, CFormLabel, CRow } from '@coreui/react'
import axios from 'axios'
import { URL__API } from 'src/axios'
import { PropTypes } from 'prop-types'
import * as toastr from 'toastr'
import 'toastr/build/toastr.css'

function ChangePassword({ history }) {
  const [fail, setFailValue] = useState(false)

  const change = async ({ ancienPassword, newPassword, confirmPassword }) => {
    try {
      const res = await axios.patch(
        `${URL__API}/user/changepassword`,
        {
          ancienPassword,
          newPassword,
          confirmPassword,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      )
      if ([200, 201].includes(res.status)) {
        toastr.success('Le mot de passe est modifiée avec succès', {
          positionClass: 'toast-bottom-left',
        })
        history.push('/compte')
      }
    } catch (error) {
      setFailValue(true)
      console.log(error)
    }
  }

  const validationSchema = Yup.object().shape({
    ancienPassword: Yup.string().required('Veuillez renseigner ce champ'),
    newPassword: Yup.string().required('Veuillez renseigner ce champ'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Votre mot de passe de confirmation est erroné')
      .required('Veuillez renseigner ce champ'),
  })

  return (
    <DefaultLayout>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          ancienPassword: '',
          newPassword: '',
          confirmPassword: '',
        }}
        onSubmit={change}
      >
        {({ values, touched, errors, handleChange, handleSubmit }) => (
          <CForm className="w-75 mt-5 mx-auto" onSubmit={handleSubmit}>
            <CRow className="m-5 w-100 mx-auto">
              <CCol xs>
                <CFormLabel>Ancien mot de passe</CFormLabel>
                <CFormInput
                  placeholder="Ancien mot de passe"
                  type="password"
                  name="ancienPassword"
                  value={values?.ancienPassword}
                  onChange={handleChange}
                />
                {errors.ancienPassword && touched.ancienPassword && (
                  <p style={{ color: 'red' }}>{errors.ancienPassword}</p>
                )}
              </CCol>
            </CRow>
            <CRow className="m-5 w-100 mx-auto">
              <CCol xs>
                <CFormLabel>Nouveau mot de passe</CFormLabel>
                <CFormInput
                  placeholder="Nouveau mot de passe"
                  type="password"
                  name="newPassword"
                  value={values?.newPassword}
                  onChange={handleChange}
                />
                {errors.newPassword && touched.newPassword && (
                  <p style={{ color: 'red' }}>{errors.newPassword}</p>
                )}
              </CCol>
            </CRow>
            <CRow className="m-5 w-100 mx-auto">
              <CCol xs>
                <CFormLabel>Mot de passe de confirmation</CFormLabel>
                <CFormInput
                  placeholder="Mot de passe de confirmation"
                  type="password"
                  name="confirmPassword"
                  value={values?.confirmPassword}
                  onChange={handleChange}
                />
                {errors.confirmPassword && touched.confirmPassword && (
                  <p style={{ color: 'red', marginTop: '5px' }}>{errors.confirmPassword}</p>
                )}
              </CCol>
            </CRow>
            <CRow className="mx-5 w-100 mx-auto">
              <CCol xs>
                <CButton
                  color="primary"
                  className="px-4 w-100 mx-auto"
                  style={{ background: '#2acc96', border: '#2acc96' }}
                  type="submit"
                >
                  Changer le mot de passe
                </CButton>
              </CCol>
            </CRow>
            {fail && (
              <CAlert
                style={{
                  marginTop: '10px',
                }}
                color="danger"
              >
                Votre ancien mot de passe est erroné
              </CAlert>
            )}
          </CForm>
        )}
      </Formik>
    </DefaultLayout>
  )
}

ChangePassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default ChangePassword
