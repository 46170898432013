import React from 'react'
import { useHistory } from 'react-router-dom'

function City() {
  let { location } = useHistory()

  return (
    <>
      <svg
        width="25"
        height="32"
        style={{ marginRight: '18px', marginLeft: '5px' }}
        viewBox="0 0 23 30"
        fill={`${location.pathname === '/cities' ? '#2ACC96' : 'white'}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.0821 0C4.97132 0 0 4.97132 0 11.0821C0 13.0939 0.902216 15.2575 0.940026 15.3488C1.23142 16.0404 1.80639 17.1147 2.22099 17.7445L9.81942 29.2575C10.1304 29.7295 10.5906 30 11.0821 30C11.5737 30 12.0339 29.7295 12.3448 29.2581L19.9439 17.7445C20.3592 17.1147 20.9335 16.0404 21.2249 15.3488C21.2627 15.2581 22.1643 13.0945 22.1643 11.0821C22.1643 4.97132 17.193 0 11.0821 0ZM20.0228 14.8429C19.7627 15.4628 19.2275 16.4622 18.8553 17.0267L11.2562 28.5404C11.1063 28.7679 11.0587 28.7679 10.9087 28.5404L3.30965 17.0267C2.93742 16.4622 2.40222 15.4622 2.14211 14.8422C2.13103 14.8155 1.30378 12.824 1.30378 11.0821C1.30378 5.69035 5.69035 1.30378 11.0821 1.30378C16.4739 1.30378 20.8605 5.69035 20.8605 11.0821C20.8605 12.8266 20.0313 14.8233 20.0228 14.8429Z"
          fill={`${location.pathname === '/cities' ? '#2ACC96' : 'white'}`}
        />
        <path
          d="M11.0819 5.21582C7.84653 5.21582 5.21484 7.84816 5.21484 11.0828C5.21484 14.3175 7.84653 16.9499 11.0819 16.9499C14.3172 16.9499 16.9489 14.3175 16.9489 11.0828C16.9489 7.84816 14.3172 5.21582 11.0819 5.21582ZM11.0819 15.6461C8.56621 15.6461 6.51863 13.5991 6.51863 11.0828C6.51863 8.56654 8.56621 6.5196 11.0819 6.5196C13.5975 6.5196 15.6451 8.56654 15.6451 11.0828C15.6451 13.5991 13.5975 15.6461 11.0819 15.6461Z"
          fill={`${location.pathname === '/cities' ? '#2ACC96' : 'white'}`}
        />
      </svg>
    </>
  )
}

export default City
