import React from 'react'
import { useHistory } from 'react-router-dom'

function Codes() {
  let { location } = useHistory()
  return (
    <div
      style={{
        color: `${location.pathname === '/codes' ? '#2ACC96' : 'white'}`,
      }}
    >
      Codes
    </div>
  )
}

export default Codes
