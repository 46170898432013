import {
  CButton,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import axios from 'axios'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { URL__API } from 'src/axios'
import DefaultLayout from 'src/layout/DefaultLayout'
import { PropTypes } from 'prop-types'
import * as Yup from 'yup'
import Loading from 'src/components/Loading'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import '../css/update.css'
import CIcon from '@coreui/icons-react'
import { cilX } from '@coreui/icons'
import * as toastr from 'toastr'
import 'toastr/build/toastr.css'
import './bricoleur.css'

const UpdateBricoleurs = ({ history, match }) => {
  const [jobs, setJobs] = useState([])
  const [cities, setCities] = useState([])
  const [bricoleur, setBricoleur] = useState(null)
  const [visible, setVisible] = useState(false)
  const [failed, setFailed] = useState(false)
  const [open, setOpen] = useState(false)
  const [images, setImages] = useState([])
  const [jobId, setJobId] = useState('')

  const fetchBricoleur = async () => {
    const res = await axios.get(`${URL__API}/user/profile/${match.params.id}`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })

    if (res.status !== 200 || res.status === 404) {
      setFailed(true)
    }
    console.log(res.data)
    setBricoleur(res.data)
  }

  useEffect(() => {
    try {
      const fetchJobs = async () => {
        const res = await axios.get(`${URL__API}/user/jobs`)

        if (res.status !== 200 || res.status === 404) {
          setFailed(true)
        }
        setJobs(res.data)
      }

      fetchJobs()

      const fetchCities = async () => {
        const res = await axios.get(`${URL__API}/cities`, {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        })

        if (res.status !== 200 || res.status === 404) {
          setFailed(true)
        }

        setCities(res.data)
      }
      fetchCities()
    } catch (error) {
      setFailed(true)
      console.log(error)
    }
  }, [match.params.id])

  const update = async ({ firstName, lastname, phone, role = '3', cin, isEmailConfirmed }) => {
    try {
      setVisible(true)
      if (visible) {
        const formData = new FormData()
        for (const img of images) {
          formData.append('file', img)
        }
        console.log({ isEmailConfirmed: !isEmailConfirmed })
        formData.append('type', 'cin')
        formData.append('firstName', firstName)
        formData.append('lastname', lastname)
        formData.append('phone', phone)
        formData.append('job', jobId)
        formData.append('cin', cin)
        formData.set('isEmailConfirmed', isEmailConfirmed)

        const res = await axios.put(
          `${URL__API}/user/update/${match.params.id}`,
          {
            type: formData.get('type'),
            firstName: formData.get('firstName'),
            lastname: formData.get('lastname'),
            phone: formData.get('phone'),
            job: formData.get('job'),
            cin: formData.get('cin'),
            isEmailConfirmed: formData.get('isEmailConfirmed') !== 'true' ? true : false,
          },
          {
            headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
          },
        )

        if ([200, 201].includes(res.status)) {
          setVisible(false)
          fetchBricoleur()
          toastr.success('Ce bricoleur est modifiée avec succès', {
            positionClass: 'toast-bottom-left',
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchBricoleur()
  }, [])

  const deletOnePhoto = async (id, label) => {
    try {
      await axios.post(
        `${URL__API}/user/${id}/photo`,
        { label },
        {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      )
      fetchBricoleur()
    } catch (error) {
      console.error(error)
    }
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Veuillez renseigner ce champ'),
    lastname: Yup.string().required('Veuillez renseigner ce champ'),
    email: Yup.string().email("Format d'email invalide").required('Veuillez renseigner ce champ'),
    phone: Yup.string().required('Veuillez renseigner ce champ'),
    cin: Yup.string().required('Veuillez renseigner ce champ'),
    address: Yup.string().required('Veuillez renseigner ce champ'),
    files: Yup.array().nullable(),
  })

  return (
    <DefaultLayout>
      {failed || !bricoleur ? (
        <Loading />
      ) : (
        <div className="">
          <CButton color="secondary" onClick={() => history.push('/bricoleurs')}>
            Retour
          </CButton>

          <Formik validationSchema={validationSchema} initialValues={bricoleur} onSubmit={update}>
            {({ values, touched, errors, handleChange, handleSubmit }) => (
              <CForm className="bricoleur_update" onSubmit={handleSubmit}>
                <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
                  <CModalHeader>
                    <CModalTitle>Modifie</CModalTitle>
                  </CModalHeader>
                  <CModalBody>Etes-vous sûr de modifier ce bricoleur ?</CModalBody>
                  <CModalFooter>
                    <CButton color="danger" onClick={() => setVisible(false)}>
                      Non
                    </CButton>
                    <CButton color="success" onClick={() => update(values)}>
                      Oui
                    </CButton>
                  </CModalFooter>
                </CModal>
                <CRow className="bricoleur_update_field">
                  <CCol xs>
                    <CFormLabel>Nom</CFormLabel>
                    <CFormInput
                      placeholder="Nom"
                      type="text"
                      name="firstName"
                      value={values?.firstName}
                      onChange={handleChange}
                    />
                    {errors.firstName && touched.firstName && (
                      <p style={{ color: 'red' }}>{errors.firstName}</p>
                    )}
                  </CCol>
                  <CCol xs>
                    <CFormLabel>Prénom</CFormLabel>
                    <CFormInput
                      placeholder="Prénom"
                      type="text"
                      name="lastname"
                      value={values?.lastname}
                      onChange={handleChange}
                    />
                    {errors.lastnames && touched.lastnames && (
                      <p style={{ color: 'red' }}>{errors.lastnames}</p>
                    )}
                  </CCol>
                </CRow>
                <CRow className="bricoleur_update_field">
                  <CCol xs>
                    <CFormLabel>Email</CFormLabel>
                    <CFormInput
                      placeholder="Email"
                      type="email"
                      name="email"
                      value={values?.email}
                      onChange={handleChange}
                    />
                    {errors.email && touched.email && (
                      <p style={{ color: 'red' }}>{errors.email}</p>
                    )}
                  </CCol>
                  <CCol xs>
                    <CFormLabel>Téléphone</CFormLabel>
                    <CFormInput
                      placeholder="Téléphone"
                      type="text"
                      name="phone"
                      value={values?.phone}
                      onChange={handleChange}
                    />
                    {errors.phone && touched.phone && (
                      <p style={{ color: 'red' }}>{errors.phone}</p>
                    )}
                  </CCol>
                </CRow>
                <CRow className="bricoleur_update_field">
                  <CCol xs>
                    <CFormLabel>Métier</CFormLabel>
                    <CFormSelect
                      value={values.job?.id}
                      onChange={(e) => {
                        setJobId(e.target.value)
                      }}
                      name="job"
                    >
                      {jobs.map((job) => (
                        <option className="py-2" key={job.id} value={job.id}>
                          {job.name}
                        </option>
                      ))}
                    </CFormSelect>
                    {errors.job && touched.job && <p style={{ color: 'red' }}>{errors.job}</p>}
                  </CCol>
                  <CCol xs>
                    <CFormLabel>Addresse</CFormLabel>
                    <CFormInput
                      placeholder="addresse"
                      type="text"
                      name="address"
                      value={values?.address}
                      onChange={handleChange}
                    />
                    {errors.address && touched.address && (
                      <p style={{ color: 'red' }}>{errors.address}</p>
                    )}
                  </CCol>
                </CRow>

                <CRow className="bricoleur_update_field">
                  <CCol xs>
                    <CFormLabel>Ville</CFormLabel>
                    <CFormSelect
                      value={values.city?.id}
                      onChange={(e) => {
                        setJobId(e.target.value)
                      }}
                      name="city"
                    >
                      {cities.map((city) => (
                        <option className="py-2" key={city.id} value={city.id}>
                          {city.label}
                        </option>
                      ))}
                    </CFormSelect>
                    {errors.job && touched.job && <p style={{ color: 'red' }}>{errors.job}</p>}
                  </CCol>
                  <CCol xs>
                    <CFormLabel>Pays</CFormLabel>
                    <CFormInput
                      placeholder="pays"
                      type="text"
                      name="country"
                      value={values?.country}
                      onChange={handleChange}
                    />
                    {errors.address && touched.address && (
                      <p style={{ color: 'red' }}>{errors.address}</p>
                    )}
                  </CCol>
                </CRow>
                <CRow className="bricoleur_update_field">
                  <CCol xs>
                    {<CFormLabel>CIN</CFormLabel>}
                    <CFormInput
                      placeholder="cin"
                      type="text"
                      name="cin"
                      value={values.cin}
                      onChange={handleChange}
                    />
                    {errors.cin && touched.cin && <p style={{ color: 'red' }}>{errors.cin}</p>}
                  </CCol>
                  <CCol xs>
                    <CFormLabel>Auto Entrepreneur</CFormLabel>
                    <CFormInput
                      placeholder="Auto Entrepreneur"
                      type="text"
                      name="autoEntrepreneur"
                      value={values?.autoEntrepreneur}
                      onChange={handleChange}
                    />
                  </CCol>
                  <CCol xs>
                    <CFormLabel>Code société</CFormLabel>
                    <CFormInput
                      placeholder="Code société"
                      type="text"
                      name="codeSociete"
                      value={values?.codeSociete}
                      onChange={handleChange}
                    />
                  </CCol>
                </CRow>
                <CRow className="m-5 bricoleur_update_check  bricoleur_update_field">
                  <CCol xs>
                    <CFormCheck
                      id="flexCheckCheckedEntre"
                      label="Auto-Entrepreneur"
                      defaultChecked={bricoleur?.isAutoEntrepreneur}
                    />
                  </CCol>
                  <CCol xs>
                    <CFormCheck
                      id="flexCheckCheckedEmail"
                      label="E-mail confirmé"
                      defaultChecked={bricoleur?.isEmailConfirmed}
                      onChange={handleChange}
                    />
                  </CCol>
                  <CCol xs>
                    <CFormCheck
                      id="flexCheckCheckedPhone"
                      label="Téléphone vérifié"
                      defaultChecked={bricoleur?.isPhoneVerified}
                    />
                  </CCol>
                </CRow>

                <CRow className="bricoleur_update_field bricoleur_img">
                  <CCol xs className="my-3">
                    {bricoleur.cinPic &&
                      !bricoleur.cinPic.includes('[]') &&
                      bricoleur.cinPic.map((img) => (
                        <div key={img} className="container__pos">
                          <img
                            alt="cin"
                            className="m-3"
                            width={275}
                            style={{ objectFit: 'fill', cursor: 'pointer' }}
                            height={200}
                            onClick={() => setOpen(true)}
                            onBlur={() => setOpen(false)}
                            src={img}
                          />
                          <CIcon
                            title="supprimer"
                            icon={cilX}
                            className="top"
                            size="xl"
                            onClick={() => deletOnePhoto(bricoleur.id, img)}
                          />
                          {open && (
                            <Lightbox
                              alt="avatar"
                              className="mx-4"
                              width={275}
                              style={{ objectFit: 'fill' }}
                              height={200}
                              mainSrc={
                                // `https://9adlia-uploads-staging.s3.eu-west-3.amazonaws.com/cin/` +
                                img
                              }
                              onCloseRequest={() => setOpen(false)}
                            />
                          )}
                        </div>
                      ))}
                  </CCol>
                </CRow>

                <CRow className="">
                  <CCol xs>
                    <CFormLabel>Change la photo</CFormLabel>
                    <CFormInput
                      placeholder="image"
                      type="file"
                      multiple
                      name="file"
                      onChange={(e) => setImages(e.target.files)}
                    />
                  </CCol>
                </CRow>

                <CRow>
                  <CCol xs>
                    <CButton type="button" className="bricoleur_btn" onClick={update}>
                      Modifier
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>
            )}
          </Formik>
        </div>
      )}
    </DefaultLayout>
  )
}

UpdateBricoleurs.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
}

export default UpdateBricoleurs
