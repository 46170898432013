import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import DefaultLayout from 'src/layout/DefaultLayout'
import axios from 'axios'
import { URL__API } from 'src/axios'
import Loading from 'src/components/Loading'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CHeader,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import Paginated from 'src/components/paginated/Paginated'
import { Link } from 'react-router-dom'
import CIcon from '@coreui/icons-react'
import { cilPen, cilPlus, cilX } from '@coreui/icons'
import avatar from '../../../assets/images/avatars/Avatars.png'
import NoData from '../nodata/NoData'

function JobCats({ history, match }) {
  const [jobCats, setJobsCats] = useState([])
  const [jobCatsId, setJobsCatsId] = useState('')
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(true)

  const fetchData = async () => {
    const res = await axios.get(`${URL__API}/jobs/${match.params.id}/categories`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    setLoading(false)
    setJobsCats(res.data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleAccept = async (id) => {
    setVisible(true)
    if (visible === true) {
      await axios.delete(`${URL__API}/categories/${id}`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      setVisible(false)
      setJobsCatsId('')
      fetchData()
    }
  }

  if (loading) {
    return <Loading />
  }

  return (
    <DefaultLayout>
      {jobCats.length === 0 ? (
        <NoData msg="des sous-categories pour ce métier" />
      ) : (
        <>
          <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
              <CModalTitle>Suppression</CModalTitle>
            </CModalHeader>
            <CModalBody>Etes-vous sûr de supprimer ce métier ?</CModalBody>
            <CModalFooter>
              <CButton color="secondary" onClick={() => setVisible(false)}>
                Non
              </CButton>
              <CButton color="primary" onClick={() => handleAccept(jobCatsId)}>
                Oui
              </CButton>
            </CModalFooter>
          </CModal>

          <CRow>
            <Paginated items={jobCats}>
              {(paginatedJobCats) => (
                <CCol xs>
                  <CCard className="mb-4">
                    <CHeader className="mr-4 d-flex">
                      <CCardHeader>{jobCats[0]?.job.name}</CCardHeader>
                      <Link
                        to="/addjobcats"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          color: 'white',
                          background: '#2ACC96',
                          border: '#2ACC96',
                          textDecoration: 'none',
                          borderRadius: '20px',
                          margin: '8px',
                          paddingRight: '15px',
                          paddingLeft: '15px',
                          paddingBottom: '16px',
                          paddingTop: '16px',
                          width: 'fit-content',
                        }}
                      >
                        <CIcon icon={cilPlus} size="lg" style={{ marginRight: '12px' }}></CIcon>
                        Ajouter une catégorie
                      </Link>
                    </CHeader>
                    <CCardBody>
                      <br />
                      <CTable align="middle" className="mb-0 border" hover responsive>
                        <CTableHead color="light">
                          <CTableRow>
                            <CTableHeaderCell className="text-center"></CTableHeaderCell>
                            <CTableHeaderCell>Nom</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Prix du service
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Prix minimum
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Actions</CTableHeaderCell>
                          </CTableRow>
                        </CTableHead>
                        <CTableBody>
                          {paginatedJobCats &&
                            paginatedJobCats
                              .sort((a, b) => a.createdAt - b.createdAt)
                              .map((item, index) => (
                                <CTableRow v-for="item in tableItems" key={index}>
                                  <CTableDataCell className="text-center">
                                    <div
                                      style={{ width: '5rem', height: '5rem', textAlign: 'center' }}
                                    >
                                      <img
                                        alt="avatar"
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                          borderRadius: '50%',
                                          objectFit: 'contain',
                                        }}
                                        src={item.job.avatar ? item.job.avatar : avatar}
                                      />
                                    </div>
                                  </CTableDataCell>
                                  <CTableDataCell>
                                    <div>{item.label}</div>
                                  </CTableDataCell>

                                  <CTableDataCell className="text-center">
                                    <div className="small text-medium-emphasis">
                                      <span>{item.priceEstimation}</span>
                                    </div>
                                  </CTableDataCell>

                                  <CTableDataCell className="text-center">
                                    <span> {item.minPrice} </span>
                                  </CTableDataCell>

                                  <CTableDataCell className="text-center">
                                    <Link to={`/categorie/${item.id}`}>
                                      <CIcon
                                        icon={cilPen}
                                        title="Modifie"
                                        style={{
                                          color: '#2ACC96',
                                          border: '2px solid #2ACC96',
                                          borderRadius: '100%',
                                          margin: '9px',
                                          cursor: 'pointer',
                                        }}
                                        size="xl"
                                      />
                                    </Link>
                                    <CIcon
                                      title="supprimer"
                                      icon={cilX}
                                      style={{
                                        cursor: 'pointer',
                                        color: 'red',
                                        border: '2px solid red',
                                        borderRadius: '100%',
                                        padding: '1px',
                                        margin: '9px',
                                      }}
                                      size="xl"
                                      onClick={() => {
                                        setVisible(true)
                                        setJobsCatsId(item.id)
                                      }}
                                    />
                                  </CTableDataCell>
                                </CTableRow>
                              ))}
                        </CTableBody>
                      </CTable>
                    </CCardBody>
                  </CCard>
                </CCol>
              )}
            </Paginated>
          </CRow>
        </>
      )}
    </DefaultLayout>
  )
}

JobCats.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
}

export default JobCats
