import React, { useEffect, useState } from 'react'
import {
  CAlert,
  CButton,
  CCard,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
} from '@coreui/react'
import axios from 'axios'
import { Formik } from 'formik'
import { URL__API } from 'src/axios'
import DefaultLayout from 'src/layout/DefaultLayout'
import { PropTypes, string } from 'prop-types'
import * as Yup from 'yup'
import { withRouter } from 'react-router-dom'
import { nanoid } from 'nanoid'

function AddCode({ history }) {
  const [fail, setFailValue] = useState(false)

  const validationSchema = Yup.object().shape({
    code: Yup.string().required('Veuillez renseigner ce champ'),
    value: Yup.number().required('Veuillez renseigner ce champ'),
    max: Yup.number().required('Veuillez renseigner ce champ'),
  })

  useEffect(() => {
    generateCode()
  }, [])

  const generateCode = () => {
    let valeur = nanoid(5).toUpperCase()
    valeur = valeur.split('')
    if (valeur.includes('_')) {
      valeur[valeur.indexOf('_')] = 'Q'
      valeur = 'Q-' + valeur.join('')
      return valeur
    } else if (valeur.includes('-')) {
      valeur[valeur.indexOf('-')] = 'Q'
      valeur = 'Q-' + valeur.join('')
      return valeur
    } else {
      valeur = 'Q-' + valeur.join('')
      return valeur
    }
  }

  const ajouter = async ({ code, value, max, type }) => {
    try {
      await axios.post(
        `${URL__API}/codepromos/new`,
        {
          code: code,
          value: parseInt(value),
          max: parseInt(max),
          type,
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      history.push('/codes')
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <DefaultLayout>
      <CCard>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            code: generateCode(),
            value: '',
            max: '',
            type: '',
          }}
          onSubmit={ajouter}
        >
          {({ values, touched, errors, handleChange, handleSubmit }) => (
            <CForm className="w-75 mt-5 mx-auto" onSubmit={handleSubmit}>
              <CRow className="mx-5 w-100 mx-auto">
                <CFormLabel>Code</CFormLabel>
                <CCol xs className="d-flex">
                  <CFormInput
                    placeholder="label"
                    type="text"
                    name="code"
                    value={values.code}
                    onChange={handleChange}
                  />
                </CCol>
                {/* {errors.code && touched.code && <p style={{ color: 'red' }}>{errors.code}</p>} */}
              </CRow>
              <CRow className="m-5 w-100 mx-auto">
                <CCol xs>
                  <CFormLabel>Valeur</CFormLabel>
                  <CFormInput
                    placeholder="valeur"
                    value={values.value}
                    type="text"
                    name="value"
                    onChange={handleChange}
                  />
                  {errors.value && touched.value && <p style={{ color: 'red' }}>{errors.value}</p>}
                </CCol>
              </CRow>

              <CRow className="m-5 w-100 mx-auto">
                <CCol xs>
                  <CFormLabel>Nombre d&apos;utilisation</CFormLabel>
                  <CFormInput
                    placeholder="Nombre d'utilisation"
                    value={values.max}
                    type="text"
                    name="max"
                    onChange={handleChange}
                  />
                  {errors.max && touched.max && <p style={{ color: 'red' }}>{errors.max}</p>}
                </CCol>
              </CRow>
              <CRow className="m-5 w-100 mx-auto">
                <CCol xs>
                  <label className="mb-3">Le type:</label>
                  <CFormSelect
                    value={values.type}
                    name="type"
                    onChange={handleChange}
                    options={[
                      { value: 0, label: 'Selectionner une type' },
                      { value: 'P', label: 'Pourcentage' },
                      { value: 'M', label: 'Mountant' },
                    ]}
                  />
                </CCol>
              </CRow>

              <CRow className="m-5 w-100 mx-auto">
                <CCol xs>
                  <CButton
                    type="submit"
                    style={{
                      color: 'white',
                      background: '#2ACC96',
                      border: '#2ACC96',
                      textDecoration: 'none',
                    }}
                    className="w-100 py-2"
                  >
                    Ajouter
                  </CButton>
                </CCol>
              </CRow>
              {fail && (
                <CAlert
                  style={{
                    marginTop: '10px',
                  }}
                  color="danger"
                >
                  Cette code existe déjà
                </CAlert>
              )}
            </CForm>
          )}
        </Formik>
      </CCard>
    </DefaultLayout>
  )
}

AddCode.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}
export default AddCode
