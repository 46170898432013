import React from 'react'
import { CFooter } from '@coreui/react'
import './app.css'

const AppFooter = () => {
  const date = new Date()

  return (
    <CFooter className="footer">
      <div className="d-flex">
        <a
          href="https://qadlia.ma/"
          target="_blank"
          rel="noreferrer"
          style={{
            color: 'rgb(42, 204, 150)',
            marginRight: '9px',
            textDecoration: 'none',
            cursor: 'pointer',
          }}
        >
          9adlia
        </a>
        <p>&copy;</p>
        <span className="ms-1"> {date.getFullYear()} </span>
      </div>
      <a
        href="https://devotech.co/"
        target="_blank"
        rel="noreferrer"
        style={{
          color: 'black',
          marginRight: '9px',
          textDecoration: 'none',
          cursor: 'pointer',
        }}
        className="mx-5"
      >
        <span className="mr-5">Powered by Devo</span>
        <span className="mr-5" style={{ color: 'red' }}>
          Tech
        </span>
      </a>
    </CFooter>
  )
}

export default React.memo(AppFooter)
