import React from 'react'
import { useHistory } from 'react-router-dom'

function Variables() {
  let { location } = useHistory()

  return (
    <div
      style={{
        color: `${location.pathname === '/variables' ? '#2ACC96' : 'white'}`,
      }}
    >
      Variables
    </div>
  )
}

export default Variables
