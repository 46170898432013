import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilSpeedometer } from '@coreui/icons'
import { CNavItem, CNavTitle } from '@coreui/react'
import Bricoleur from './icons/Bricoleur'
import User from './icons/User'
import Compte from './icons/Compte'
import Job from './icons/Job'
import Offer from './icons/Offer'
import Bricoleurs from './components/names/Bricoleurs'
import Users from './components/names/Users'
import Comptes from './components/names/Comptes'
import Metier from './components/names/Metier'
import Demandes from './components/names/Demandes'
import Variables from './components/names/Variables'
import Variable from './icons/Variable'
import Signal from './icons/Signal'
import Signals from './components/names/Signals'
import Messages from './components/names/Messages'
import Chat from './icons/Chat'
import City from './icons/City'
import Cities from './components/names/Cities'
import MessageInfo from './views/pages/messages/MessageInfo'
import Codes from './components/names/Codes'
import Code from './icons/Code'
import Dashboard from './components/names/Dashboard'
import DashboardIcon from './icons/DashboardIcon'
import JobCats from './views/pages/jobs/JobCats'

const _nav = [
  {
    component: CNavItem,
    name: <Dashboard />,
    to: '/',
    icon: <DashboardIcon />,
  },
  {
    component: CNavTitle,
    name: '',
  },
  {
    component: CNavItem,
    name: <Bricoleurs />,
    to: '/bricoleurs',
    icon: <Bricoleur />,
  },
  {
    component: CNavItem,
    name: <Users />,
    to: '/users',
    icon: <User />,
  },
  {
    component: CNavItem,
    name: <Comptes />,
    to: '/compte',
    icon: <Compte />,
  },
  {
    component: CNavItem,
    name: <Metier />,
    to: '/métier',
    icon: <Job />,
  },
  {
    component: CNavItem,
    name: <Demandes />,
    to: '/demandes',
    icon: <Offer />,
  },
  {
    component: CNavItem,
    name: <Variables />,
    to: '/variables',
    icon: <Variable />,
  },
  {
    component: CNavItem,
    name: <Signals />,
    to: '/signalements',
    icon: <Signal />,
  },
  {
    component: CNavItem,
    name: <Messages />,
    to: '/messages',
    icon: <Chat />,
  },
  {
    component: CNavItem,
    name: <Cities />,
    to: '/cities',
    icon: <City />,
  },
  {
    component: CNavItem,
    name: <Codes />,
    to: '/codes',
    icon: <Code />,
  },
]

export default _nav
