import React from 'react'
import PropTypes from 'prop-types'
import { CCardHeader, CCol, CContainer, CHeader, CRow } from '@coreui/react'
import { Link } from 'react-router-dom'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'

function NoUsers({ msg }) {
  return (
    <div
      style={{
        maxWidth: '1440px',
        height: '700px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
      }}
    >
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <div className="text-center">
              <h4 className="pt-3">Oops!</h4>
              <p className="text-center">
                Il n{"'"}y a pas encore {msg}
              </p>
            </div>
          </CCol>
        </CRow>
        {msg && (
          <Link
            to="/addusers"
            style={{
              display: 'flex',
              alignItems: 'center',
              color: 'white',
              background: '#2ACC96',
              border: '#2ACC96',
              textDecoration: 'none',
              borderRadius: '20px',
              margin: '20px',
              paddingRight: '25px',
              paddingLeft: '25px',
              paddingBottom: '16px',
              paddingTop: '16px',
              justifyContent: 'center',
            }}
          >
            <CIcon icon={cilPlus} size="lg" style={{ marginRight: '12px' }}></CIcon>
            Ajouter un client
          </Link>
        )}
      </CContainer>
    </div>
  )
}

NoUsers.propTypes = {
  msg: PropTypes.string,
}

export default NoUsers
