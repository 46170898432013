import React from 'react'
import PropTypes from 'prop-types'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'
import { useHistory } from 'react-router-dom'

const DefaultLayout = ({ children }) => {
  let history = useHistory()

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">{children}</div>
        <AppFooter />
      </div>
    </div>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.any,
}

export default DefaultLayout
