import React, { useEffect, useState } from 'react'
import {
  CAlert,
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
} from '@coreui/react'
import axios from 'axios'
import { Formik } from 'formik'
import { URL__API } from 'src/axios'
import DefaultLayout from 'src/layout/DefaultLayout'
import { PropTypes } from 'prop-types'
import * as Yup from 'yup'
import { withRouter } from 'react-router-dom'
import Loading from 'src/components/Loading'

function UpdateJobCats({ history, match }) {
  const [failed, setFailed] = useState('')
  const [categorie, setCategorie] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${URL__API}/categories/${match.params.id}`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      setCategorie(res.data)
    }

    fetchData()
  }, [])

  const update = async ({ label, priceEstimation, minPrice, translation }) => {
    try {
      const res = await axios.patch(
        `${URL__API}/categories/${match.params.id}`,
        {
          label,
          priceEstimation,
          minPrice,
          translation,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      )

      if (res.status === 200) {
        history.push('/métier')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const validationSchema = Yup.object().shape({
    label: Yup.string().required('Veuillez renseigner ce champ'),
    priceEstimation: Yup.number().required('Veuillez renseigner ce champ'),
    minPrice: Yup.number().required('Veuillez renseigner ce champ'),
    translation: Yup.string().required('Veuillez renseigner ce champ'),
  })

  return (
    <DefaultLayout>
      {failed || !categorie ? (
        <Loading />
      ) : (
        <div className="">
          <Formik validationSchema={validationSchema} initialValues={categorie} onSubmit={update}>
            {({ values, touched, errors, handleChange, handleSubmit, setFieldValue }) => (
              <CForm className="w-75 mt-5 mx-auto" onSubmit={handleSubmit}>
                <CRow className="mx-5">
                  <CCol xs>
                    <CFormLabel>Nom de categorie</CFormLabel>
                    <CFormInput
                      placeholder="Categorie"
                      type="text"
                      name="label"
                      value={values.label}
                      onChange={handleChange}
                    />
                    {errors.label && touched.label && (
                      <p style={{ color: 'red' }}>{errors.label}</p>
                    )}
                  </CCol>
                </CRow>
                <CRow className="m-5">
                  <CCol xs>
                    <CFormLabel>Prix du service</CFormLabel>
                    <CFormInput
                      placeholder="Prix du service"
                      type="number"
                      name="priceEstimation"
                      value={values.priceEstimation}
                      onChange={handleChange}
                    />
                    {errors.priceEstimation && touched.priceEstimation && (
                      <p style={{ color: 'red' }}>{errors.priceEstimation}</p>
                    )}
                  </CCol>
                </CRow>

                <CRow className="m-5">
                  <CCol xs>
                    <CFormLabel>Prix minimum</CFormLabel>
                    <CFormInput
                      placeholder="Prix minimum"
                      type="number"
                      name="minPrice"
                      value={values.minPrice}
                      onChange={handleChange}
                    />
                    {errors.minPrice && touched.minPrice && (
                      <p style={{ color: 'red' }}>{errors.minPrice}</p>
                    )}
                  </CCol>
                </CRow>

                <CRow className="m-5">
                  <CCol xs>
                    <CFormLabel>Traduction</CFormLabel>
                    <CFormInput
                      placeholder="Traduction"
                      type="text"
                      name="translation"
                      // value={!trans ? job?.translations?.ar : trans}
                      value={values?.translations?.ar}
                      // onChange={(e) => {
                      //   if (values?.translations?.ar) {
                      //     setTrans('')
                      //   }
                      //   setTrans(e.target.value)
                      //   handleChange(trans)
                      // }}
                      onChange={handleChange}
                    />
                    {errors.translation && touched.translation && (
                      <p style={{ color: 'red' }}>{errors.translation}</p>
                    )}
                  </CCol>
                </CRow>

                <CRow className="m-5">
                  <CCol xs>
                    <CButton
                      type="submit"
                      style={{
                        color: 'white',
                        background: '#2ACC96',
                        border: '#2ACC96',
                        textDecoration: 'none',
                      }}
                      className="w-100 py-2"
                    >
                      Modifier
                    </CButton>
                  </CCol>
                </CRow>
                {/* {fail && (
                <CAlert
                  style={{
                    marginTop: '10px',
                  }}
                  color="danger"
                >
                  Ce metrier existe déjà
                </CAlert>
              )} */}
              </CForm>
            )}
          </Formik>
        </div>
      )}
    </DefaultLayout>
  )
}

UpdateJobCats.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
}

export default UpdateJobCats
