import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CHeader,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { URL__API } from 'src/axios'
import Loading from 'src/components/Loading'
import Paginated from 'src/components/paginated/Paginated'
import DefaultLayout from 'src/layout/DefaultLayout'
import CIcon from '@coreui/icons-react'
import { cilPen, cilX } from '@coreui/icons'

function Cities() {
  const [cities, setCities] = useState([])
  const [visible, setVisible] = useState(false)
  const [cityId, setCityId] = useState('')

  const fetchCities = async () => {
    const res = await axios.get(`${URL__API}/cities`)

    setCities(res.data)
  }

  useEffect(() => {
    fetchCities()
  }, [])

  const handleAccept = async (id) => {
    setVisible(true)

    if (visible === true) {
      const res = await axios.delete(`${URL__API}/cities/${+id}`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      console.log(res)
      setVisible(false)
      setCityId('')
      fetchCities()
    }
  }

  return (
    <DefaultLayout>
      {cities.length === 0 ? (
        <Loading />
      ) : (
        <>
          <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
              <CModalTitle>Suppression</CModalTitle>
            </CModalHeader>
            <CModalBody>Etes-vous sûr de supprimer cette ville ?</CModalBody>
            <CModalFooter>
              <CButton color="secondary" onClick={() => setVisible(false)}>
                Non
              </CButton>
              <CButton color="primary" onClick={() => handleAccept(cityId)}>
                Oui
              </CButton>
            </CModalFooter>
          </CModal>
          <Paginated items={cities}>
            {(paginatedVariables) => (
              <CCol xs>
                <CCard className="mb-4">
                  <CHeader className="mr-4">
                    <CCardHeader>Villes</CCardHeader>
                  </CHeader>
                  <CCardBody>
                    <br />
                    <CTable align="middle" className="mb-0 border" hover responsive>
                      <CTableHead color="light">
                        <CTableRow>
                          <CTableHeaderCell className="text-center">Ville</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Traduction</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Actions</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>

                      <CTableBody>
                        {paginatedVariables &&
                          paginatedVariables.map((item, index) => (
                            <CTableRow v-for="item in tableItems" key={index}>
                              <CTableDataCell className="text-center">
                                <p>{item.label}</p>
                              </CTableDataCell>
                              <CTableDataCell className="text-center">
                                <p>{item?.translations['ar'] ? item?.translations['ar'] : '-'}</p>
                                {/* <Link to={`${item?.id}/chat/${item.sender.id}/${item.receiver.id}`}>
                                  Afficher les messages
                                </Link> */}
                              </CTableDataCell>
                              <CTableDataCell className="text-center">
                                <Link to={`/put/city/${item.id}`}>
                                  <CIcon
                                    icon={cilPen}
                                    title="Modifie"
                                    style={{
                                      color: '#2ACC96',
                                      border: '2px solid #2ACC96',
                                      borderRadius: '100%',
                                      margin: '9px',
                                      cursor: 'pointer',
                                    }}
                                    size="xl"
                                  />
                                </Link>
                                <CIcon
                                  title="supprimer"
                                  icon={cilX}
                                  style={{
                                    cursor: 'pointer',
                                    color: 'red',
                                    border: '2px solid red',
                                    borderRadius: '100%',
                                    padding: '1px',
                                    margin: '9px',
                                  }}
                                  size="xl"
                                  onClick={() => {
                                    setVisible(true)
                                    setCityId(item.id)
                                  }}
                                />
                              </CTableDataCell>
                            </CTableRow>
                          ))}
                      </CTableBody>
                    </CTable>
                  </CCardBody>
                </CCard>
              </CCol>
            )}
          </Paginated>
        </>
      )}
    </DefaultLayout>
  )
}

export default Cities
