import { cilSpeedometer } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import React from 'react'
import { useHistory } from 'react-router-dom'

function DashboardIcon() {
  let { location } = useHistory()

  return (
    <>
      <svg
        width="25"
        height="25"
        style={{ marginRight: '15px', marginLeft: '5px' }}
        viewBox="0 0 24 25"
        fill={`${location.pathname === '/' ? '#2ACC96' : 'white'}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4316 16.2027C14.7861 16.2027 15.8886 17.2973 15.8886 18.6428V22.1033C15.8886 22.3924 16.1204 22.6242 16.4174 22.6309H18.5616C20.2514 22.6309 21.625 21.2742 21.625 19.6069V9.79241C21.6171 9.21866 21.3438 8.67866 20.8746 8.31978L13.4575 2.40453C12.4619 1.61591 11.0691 1.61591 10.0701 2.40678L2.70362 8.31753C2.2165 8.68766 1.94313 9.22766 1.9375 9.81153V19.6069C1.9375 21.2742 3.31113 22.6309 5.00087 22.6309H7.16537C7.47025 22.6309 7.71775 22.389 7.71775 22.092C7.71775 22.0268 7.72563 21.9615 7.73913 21.8997V18.6428C7.73913 17.3052 8.83488 16.2117 10.1793 16.2027H13.4316ZM18.5616 24.3184H16.3971C15.1574 24.2892 14.2011 23.316 14.2011 22.1033V18.6428C14.2011 18.2277 13.8558 17.8902 13.4316 17.8902H10.1849C9.76975 17.8924 9.42662 18.231 9.42662 18.6428V22.092C9.42662 22.1764 9.41538 22.2574 9.39175 22.3339C9.27025 23.4477 8.3185 24.3184 7.16537 24.3184H5.00087C2.38075 24.3184 0.25 22.2045 0.25 19.6069V9.80366C0.26125 8.68541 0.7765 7.66166 1.66637 6.98778L9.01825 1.08716C10.6371 -0.195344 12.8927 -0.195344 14.5082 1.08491L21.913 6.99116C22.7826 7.65378 23.2979 8.67528 23.3125 9.78003V19.6069C23.3125 22.2045 21.1818 24.3184 18.5616 24.3184Z"
          fill={`${location.pathname === '/' ? '#2ACC96' : 'white'}`}
        />
      </svg>
    </>
  )
}

export default DashboardIcon
