import React from 'react'
import { useHistory } from 'react-router-dom'

function Signal() {
  let { location } = useHistory()

  return (
    <>
      <svg
        width="25"
        height="30"
        style={{ marginRight: '15px', marginLeft: '9px' }}
        viewBox="0 0 32 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.969612 0C0.433896 0 0 0.434252 0 0.969613V42.6667C0 43.2024 0.434251 43.6363 0.969612 43.6363C1.50533 43.6363 1.93923 43.2021 1.93923 42.6667V0.969613C1.93923 0.433897 1.50497 0 0.969612 0ZM28.2121 3.87889C26.2333 3.89309 23.4698 4.34053 19.8788 5.89387C10.5766 9.91847 2.90901 4.54547 2.90901 4.54547V22.0607C8.1776 25.4004 14.3038 25.743 19.8788 23.4091C28.1282 19.9553 32 22.0759 32 22.0759V4.56071C32 4.56071 30.7564 3.86096 28.2121 3.87889Z"
          fill={`${location.pathname === '/signalements' ? '#2ACC96' : 'white'}`}
        />
      </svg>
    </>
  )
}

export default Signal
