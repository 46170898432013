import React from 'react'
import { useHistory } from 'react-router-dom'

function User() {
  let { location } = useHistory()

  return (
    <>
      <svg
        width="35"
        height="35"
        style={{ marginRight: '10px', marginLeft: '0px' }}
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.638 32.345C23.097 32.345 25.9107 29.5309 25.9107 26.072C25.9107 22.6131 23.0966 19.7993 19.638 19.7993C16.1798 19.7993 13.365 22.613 13.365 26.072C13.365 29.531 16.1791 32.345 19.638 32.345ZM19.638 22.4388C21.6415 22.4388 23.2712 24.0685 23.2712 26.072C23.2712 28.0755 21.6415 29.7052 19.638 29.7052C17.6345 29.7052 16.0048 28.0751 16.0048 26.072C16.0048 24.0689 17.6345 22.4388 19.638 22.4388Z"
          fill={`${location.pathname === '/users' ? '#2ACC96' : 'white'}`}
        />
        <path
          d="M12.44 41.576C12.44 38.8807 14.6326 36.6885 17.3275 36.6885H21.9482C22.0864 36.6885 22.2235 36.694 22.3591 36.7057C22.7415 36.7389 23.1212 36.6104 23.3989 36.3553C23.6692 36.1061 23.8188 35.7655 23.8188 35.3967C23.8188 34.7237 23.3036 34.1463 22.645 34.083C22.4153 34.0611 22.1829 34.0494 21.9478 34.0494H17.327C13.177 34.0482 9.80005 37.4252 9.80005 41.576V42.1186H12.4398L12.44 41.576Z"
          fill={`${location.pathname === '/users' ? '#2ACC96' : 'white'}`}
        />
        <path
          d="M24.2869 42.118H26.9267V38.8684C26.9267 35.8219 29.4052 33.3434 32.4521 33.3434H37.5474C40.5939 33.3434 43.0728 35.8219 43.0728 38.8684V42.118H45.7126V38.8684C45.7126 36.1125 44.329 33.5594 42.011 32.0399C40.6782 31.1657 39.1348 30.7036 37.5477 30.7036H32.4524C29.9305 30.7036 27.5872 31.8516 26.0223 33.8532C24.8871 35.3052 24.2875 37.0395 24.2875 38.8684L24.2879 42.118H24.2869Z"
          fill={`${location.pathname === '/users' ? '#2ACC96' : 'white'}`}
        />
        <path
          d="M52.6731 34.048H48.0524C47.9231 34.048 47.7949 34.0519 47.6672 34.0586C46.9555 34.0976 46.3977 34.6785 46.3977 35.3813C46.3977 35.7441 46.5485 36.0941 46.8114 36.3422C47.0762 36.5914 47.4227 36.7164 47.7836 36.6957C47.8727 36.6902 47.9622 36.6879 48.0524 36.6879H52.6731C55.3684 36.6879 57.5606 38.8805 57.5606 41.5754V42.118L60.2004 42.1184V41.5758C60.2004 37.425 56.8234 34.0481 52.6731 34.0481L52.6731 34.048Z"
          fill={`${location.pathname === '/users' ? '#2ACC96' : 'white'}`}
        />
        <path
          d="M50.362 32.345C53.821 32.345 56.635 29.5309 56.635 26.072C56.635 22.6131 53.8209 19.7993 50.362 19.7993C46.9026 19.7993 44.0886 22.613 44.0886 26.072C44.0886 29.531 46.9034 32.345 50.362 32.345ZM50.362 22.4388C52.3655 22.4388 53.9952 24.0685 53.9952 26.072C53.9952 28.0755 52.3651 29.7052 50.362 29.7052C48.3589 29.7052 46.7288 28.0751 46.7288 26.072C46.7284 24.0689 48.3589 22.4388 50.362 22.4388Z"
          fill={`${location.pathname === '/users' ? '#2ACC96' : 'white'}`}
        />
        <path
          d="M34.9999 29.664C39.3507 29.664 42.8909 26.1242 42.8909 21.773C42.8909 17.4218 39.3511 13.882 34.9999 13.882C30.6487 13.882 27.1089 17.4218 27.1089 21.773C27.1089 26.1238 30.6487 29.664 34.9999 29.664ZM34.9999 16.521C37.8952 16.521 40.2511 18.8769 40.2511 21.7726C40.2511 24.6679 37.8952 27.0238 34.9999 27.0238C32.1042 27.0238 29.7483 24.6679 29.7483 21.7726C29.7483 18.8769 32.1042 16.521 34.9999 16.521Z"
          fill={`${location.pathname === '/users' ? '#2ACC96' : 'white'}`}
        />
      </svg>
    </>
  )
}

export default User
