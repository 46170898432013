import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import DefaultLayout from 'src/layout/DefaultLayout'
import axios from 'axios'
import { URL__API } from 'src/axios'
import avatar from '../../../assets/images/avatars/Avatars.png'
import { CCard, CCol, CListGroupItem, CRow } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilAt, cilPeople, cilPhone } from '@coreui/icons'
import { Link } from 'react-router-dom'

function SignalInfo({ history, match }) {
  const [signal, setSignal] = useState(null)

  useEffect(() => {
    const fetchOneSignal = async () => {
      const res = await axios.get(`${URL__API}/user/${match.params.id}/signal`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })

      setSignal(res.data)
    }
    fetchOneSignal()
  }, [match.params.id])
  return (
    <DefaultLayout>
      <CCard className="pb-3">
        <CRow>
          <div className="d-flex">
            <CCol className="w-75 mt-4" xs={15}>
              <h3 className=" mx-auto" style={{ width: '85%' }}>
                Signal par :
              </h3>
              <div className="d-flex align-items-center mt-4 w-75 mx-auto">
                <div
                  style={{
                    width: '4rem',
                    height: '4rem',
                    textAlign: 'center',
                    marginRight: '15px',
                  }}
                >
                  <img
                    alt="avatar"
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '50%',
                    }}
                    src={signal?.signaling?.avatar ? signal?.signaling?.avatar : avatar}
                  />
                </div>
                <div className="d-flex flex-xl-column">
                  <p className="mb-0">
                    <CIcon icon={cilPeople} /> Nom complet:{' '}
                    <Link
                      to={`/${
                        signal?.signaling?.role?.name === 'Bricoleur' ? 'bricoleur' : 'user'
                      }/${signal?.signaling?.id}`}
                    >
                      {signal?.signaling?.firstName + ' ' + signal?.signaling?.lastname}
                    </Link>
                  </p>
                  <p className="mb-0">
                    <CIcon icon={cilAt} /> Email: {signal?.signaling?.email}
                  </p>
                  <p className="mb-0">
                    <CIcon icon={cilPhone} /> Téléphone: {signal?.signaling?.phone}
                  </p>
                </div>
              </div>
            </CCol>
            <CCol className="w-75 mt-4" xs={15}>
              <h3 className=" mx-auto" style={{ width: '85%' }}>
                Target :
              </h3>
              <div className="d-flex align-items-center mt-4 w-75 mx-auto">
                <div
                  style={{
                    width: '4rem',
                    height: '4rem',
                    textAlign: 'center',
                    marginRight: '15px',
                  }}
                >
                  <img
                    alt="avatar"
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '50%',
                    }}
                    src={signal?.target?.avatar ? signal?.target?.avatar : avatar}
                  />
                </div>
                <div className="d-flex flex-xl-column">
                  <p className="mb-0">
                    <CIcon icon={cilPeople} /> Nom complet:{' '}
                    {/* {signal?.target?.firstName + ' ' + signal?.target?.lastname} */}
                    <Link
                      to={`/${signal?.target?.role?.name === 'Bricoleur' ? 'bricoleur' : 'user'}/${
                        signal?.signaling?.id
                      }`}
                    >
                      {signal?.signaling?.firstName + ' ' + signal?.signaling?.lastname}
                    </Link>
                  </p>
                  <p className="mb-0">
                    <CIcon icon={cilAt} /> Email:{signal?.target?.email}
                  </p>
                  <p className="mb-0">
                    <CIcon icon={cilPhone} /> Téléphone: {signal?.target?.phone}
                  </p>
                </div>
              </div>
            </CCol>
          </div>
        </CRow>

        <CRow>
          <CCol className="w-75 mt-4" xs={15}>
            <h3 className="mx-auto my-3" style={{ width: '85%' }}>
              Raisons :
            </h3>
            {signal?.raisons?.map((raison, index) => (
              <CListGroupItem className="mx-auto py-3" style={{ width: '85%' }} key={index}>
                {raison}
              </CListGroupItem>
            ))}
          </CCol>
        </CRow>
        <CRow>
          {signal?.another && (
            <div className=" ">
              <h3 className="mx-auto my-3" style={{ width: '88%' }}>
                Autre :
              </h3>
              <li className="mx-auto py-3" style={{ width: '85%' }}>
                {signal?.another}
              </li>
            </div>
          )}
        </CRow>
      </CCard>
    </DefaultLayout>
  )
}

SignalInfo.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
}

export default SignalInfo
