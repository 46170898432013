import React, { useCallback, useEffect, useState } from 'react'
import {
  CButton,
  CCard,
  CCol,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CImage,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import axios from 'axios'
import { URL__API } from 'src/axios'
import DefaultLayout from 'src/layout/DefaultLayout'
import { PropTypes } from 'prop-types'
import Loading from 'src/components/Loading'
import '../css/update.css'
import CIcon from '@coreui/icons-react'
import { cilTrash, cilX } from '@coreui/icons'
import * as toastr from 'toastr'
import 'toastr/build/toastr.css'
import avatar from '../../../assets/images/avatars/Avatars.png'

function UpdateDemande({ history, match }) {
  const [offer, setOffer] = useState(null)
  const [jobs, setJobs] = useState([])
  const [proofImg, setProofImg] = useState([])
  const [descriptions, setDescriptions] = useState([])
  const [states, setStates] = useState([])
  const [images, setImages] = useState([])
  const [fail, setFailValue] = useState(false)
  const [visible, setVisible] = useState(false)
  const [values, setValues] = useState({
    description: '',
    need: '',
    state: '',
    job: '',
  })

  const fetchOneOffer = useCallback(async () => {
    // final url = Uri.parse('$baseUrl/offers/$offerId/infos');

    // const res = await axios.get(`${URL__API}/offers/${match.params.id}/infos`, {
    const res = await axios.get(`${URL__API}/offers/${match.params.id}`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })

    setOffer(res.data)
    console.log(res.data)
    setProofImg(offer?.userToOffer[0]?.proof_photos)

    setDescriptions(res.data.userToOffer)
  }, [match.params.id, offer?.userToOffer])
  useEffect(() => {
    // fetch states
    const fetchStates = async () => {
      const res = await axios.get(`${URL__API}/offers/states`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      })

      setStates(res.data)
    }

    fetchStates()
  }, [match.params.id])

  useEffect(() => {
    try {
      const fetchJobs = async () => {
        const res = await axios.get(`${URL__API}/user/jobs`)

        if (res.status !== 200 || res.status === 404) {
        }
        setJobs(res.data)
      }
      fetchOneOffer()
      fetchJobs()
    } catch (error) {
      console.log(error)
    }
  }, [fetchOneOffer])

  const update = async () => {
    try {
      setVisible(true)

      if (visible) {
        const formData = new FormData()
        formData.append('need', values.need)
        formData.append('state', values.state)
        formData.append('job', values.job)
        for (const img of images) {
          formData.append('file', img)
        }
        formData.append('type', 'offer')
        const res = await axios.patch(
          `${URL__API}/offers/update-offer/${match.params.id}`,
          formData,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        if ([200, 201].includes(res.status)) {
          setVisible(false)
          fetchOneOffer()
          toastr.success("L'offre est modifiée avec succès", {
            positionClass: 'toast-bottom-left',
          })
        }
      }
    } catch (error) {
      if (error) {
        setFailValue(true)
      }
      console.error(error)
    }
  }

  const deletOnePhoto = async (id, label) => {
    try {
      await axios.post(
        `${URL__API}/offers/${id}/photo`,
        { label },
        {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      )
      fetchOneOffer()
    } catch (error) {
      console.error(error)
    }
  }

  const handleChangeDescription = async (id, e) => {
    e.preventDefault()

    try {
      const target = descriptions.findIndex((arg) => arg.userToOfferId === id)

      descriptions[target].description = e.target.value
      setValues({ description: descriptions[target].description })
      await axios.patch(
        `${URL__API}/user/desc/${id}`,
        { description: descriptions[target].description },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
    } catch (error) {
      if (error) {
        setFailValue(true)
      }
      console.error(error)
    }
  }

  // useEffect(() => {
  // console.log(offer)

  // if (res[index]?.proof_photos > 0) {
  //   res[index]?.proof_photos?.splice(0, 1)
  // }
  // console.log(res)
  // setProofImg(res[index]?.proof_photos)
  // return () => {
  //   isProof = true
  // }
  // }, [offer?.userToOffer])

  return (
    <DefaultLayout>
      {fail || !offer ? (
        <Loading />
      ) : (
        <CCard className="w-100 my-5 mx-auto">
          <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
              <CModalTitle>Modifie</CModalTitle>
            </CModalHeader>
            <CModalBody>Etes-vous sûr de modifier cette demande ?</CModalBody>
            <CModalFooter>
              <CButton color="danger" onClick={() => setVisible(false)}>
                Non
              </CButton>
              <CButton color="success" onClick={() => update(values)}>
                Oui
              </CButton>
            </CModalFooter>
          </CModal>

          <div className="d-flex w-100 align-items-center">
            {offer?.userToOffer.length > 0 && (
              <div className="w-75">
                <CRow className="align-items-center">
                  {offer?.userToOffer?.map((item) => (
                    <CCol className="w-75 mt-4" xs={15} key={item.userToOfferId}>
                      <h3 className=" mx-auto" style={{ width: '85%' }}>
                        Créé par :
                      </h3>
                      <div className="d-flex align-items-center mt-4 w-75 mx-auto">
                        <div
                          style={{
                            width: '4rem',
                            height: '4rem',
                            textAlign: 'center',
                            marginRight: '15px',
                          }}
                        >
                          <img
                            alt="avatar"
                            style={{
                              width: '100%',
                              height: '100%',
                              borderRadius: '50%',
                            }}
                            src={
                              item?.client?.avatar?.startsWith('https')
                                ? item?.client?.avatar
                                : avatar
                            }
                          />
                        </div>
                        <p>{item.client.firstName + ' ' + item.client.lastname}</p>
                      </div>
                    </CCol>
                  ))}
                </CRow>
              </div>
            )}
            <CRow>
              <CCol>
                <div className="d-flex my-3 mx-5">
                  La référence : <p className="px-3 text-primary">{offer?.offer_ref}</p>
                </div>
              </CCol>
            </CRow>
          </div>

          <CRow className="mx-5">
            <CCol xs>
              {<CFormLabel className="mt-5">Besoin</CFormLabel>}
              <CFormInput
                placeholder="description"
                type="text"
                name="need"
                className="mb-5"
                value={values.need || offer.need}
                onChange={(e) => setValues({ ...values, need: e.target.value })}
              />
            </CCol>
          </CRow>

          <CRow className="mx-5">
            <CCol xs>
              <CFormLabel>Etat</CFormLabel>
              <CFormSelect
                name="state"
                value={values.state || offer.state.id}
                onChange={(e) => setValues({ ...values, state: e.target.value })}
              >
                {states.map((state) => (
                  <option className="py-2" key={state.id} value={state.id}>
                    {state.label}
                  </option>
                ))}
              </CFormSelect>
            </CCol>
          </CRow>

          <CRow className="m-5">
            <CCol xs>
              <CFormLabel>Metier</CFormLabel>
              <CFormSelect
                value={values.job || offer.job.id}
                onChange={(e) => setValues({ ...values, job: e.target.value })}
                name="job"
              >
                {jobs.map((job) => (
                  <option className="py-2" key={job.id} value={job.id}>
                    {job.name}
                  </option>
                ))}
              </CFormSelect>
            </CCol>
          </CRow>

          <CRow className="mx-5">
            <CCol xs>
              <CFormLabel>Addresse</CFormLabel>
              <CFormInput
                placeholder="addresse"
                type="text"
                name="need"
                className="mb-5"
                value={values.address || offer.address}
                onChange={(e) => setValues({ ...values, need: e.target.value })}
              />
            </CCol>
            <CCol xs>
              <CFormLabel>Ville</CFormLabel>
              <CFormInput
                placeholder="ville"
                type="text"
                name="need"
                className="mb-5"
                value={values?.city?.label || offer?.city?.label}
                onChange={(e) => setValues({ ...values, need: e.target.value })}
              />
            </CCol>
          </CRow>

          <CRow className="mx-5 d-flex align-items-center">
            <CCol xs>
              <CFormLabel>Latitude</CFormLabel>
              <CFormInput
                placeholder="latitude"
                type="text"
                name="latitude"
                className="mb-5"
                value={values?.latitude || offer?.latitude}
                onChange={(e) => setValues({ ...values, need: e.target.value })}
              />
            </CCol>
            <CCol xs>
              <CFormLabel>Longitude</CFormLabel>
              <CFormInput
                placeholder="longitude"
                type="text"
                name="longitude"
                className="mb-5"
                value={values?.longitude || offer?.longitude}
                onChange={(e) => setValues({ ...values, need: e.target.value })}
              />
            </CCol>
            <CCol xs>
              <CFormCheck
                id="flexCheckChecked"
                label="Urgence"
                defaultChecked={values?.isEmergency || offer.isEmergency}
              />
            </CCol>
          </CRow>

          <CRow className="mx-5">
            <CCol xs>
              {descriptions.map(
                (item, index) =>
                  item.description && (
                    <div key={index}>
                      {descriptions.length > 0 && (
                        <CFormLabel className="mt-5">Description</CFormLabel>
                      )}
                      <CFormInput
                        key={item.userToOfferId}
                        placeholder="description"
                        type="text"
                        className="my-3"
                        name="description"
                        value={values.description || item.description}
                        onChange={(e) => {
                          handleChangeDescription(item.userToOfferId, e)
                        }}
                      />
                    </div>
                  ),
              )}
            </CCol>
          </CRow>

          <CRow className="my-5 mx-4">
            {offer.photos && offer.photos.includes('[]') ? null : (
              <CCol xs className="d-flex">
                {offer.photos.map((photo, index) => (
                  <div key={index} className="container__pos">
                    <img className="mx-4" width={250} height={200} src={photo} alt="photos" />

                    <CIcon
                      title="supprimer"
                      icon={cilX}
                      className="top"
                      size="xl"
                      onClick={() => deletOnePhoto(offer.id, photo)}
                    />
                  </div>
                ))}
              </CCol>
            )}
          </CRow>

          <CRow className="m-5">
            <CCol xs>
              <CFormLabel>Change la photo</CFormLabel>
              <CFormInput
                placeholder="image"
                type="file"
                multiple
                name="file"
                onChange={(e) => setImages(e.target.files)}
              />
            </CCol>
          </CRow>

          {offer?.userToOffer.length > 0 && (
            <CRow>
              {offer?.userToOffer?.map((item, index) => (
                <CCol className="w-75" xs={15} key={index}>
                  <h3 className="mx-auto" style={{ width: '85%' }}>
                    Les bricoleurs :
                  </h3>
                  <div className="d-flex align-items-center mt-4 w-75 mx-auto">
                    <div
                      style={{
                        width: '4rem',
                        height: '4rem',
                        textAlign: 'center',
                        marginRight: '15px',
                      }}
                    >
                      <img
                        alt="avatar"
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                        }}
                        src={
                          item?.bricoleur?.avatar
                            ? 'https://9adlia-uploads-staging.s3.eu-west-3.amazonaws.com/avatar/' +
                              item?.bricoleur?.avatar
                            : avatar
                        }
                      />
                    </div>
                    <p>{item?.bricoleur?.firstName + ' ' + item?.bricoleur?.lastname}</p>
                  </div>
                </CCol>
              ))}
            </CRow>
          )}

          <CRow className="my-5 mx-4">
            {proofImg?.length > 0 && !proofImg.includes('[]') && (
              <h3 className="mx-auto" style={{ width: '95%' }}>
                Les photos de verification :
              </h3>
            )}
            {proofImg?.length > 0 && !proofImg.includes('[]') && (
              <div className="img__container">
                {proofImg?.map((photo, index) => (
                  <div key={index} className="container__pos">
                    {photo.includes('[]') ? null : (
                      <CImage
                        className="mx-4"
                        width={250}
                        height={200}
                        src={
                          'https://9adlia-uploads-staging.s3.eu-west-3.amazonaws.com/proof/' + photo
                        }
                        alt="photos"
                      />
                    )}

                    <CIcon
                      title="supprimer"
                      icon={cilX}
                      className="top"
                      size="xl"
                      onClick={() => deletOnePhoto(offer.id, photo)}
                    />
                  </div>
                ))}
              </div>
            )}
          </CRow>

          <CRow className="m-5">
            <CCol xs>
              <CButton
                type="submit"
                style={{
                  color: 'white',
                  background: '#2ACC96',
                  border: '#2ACC96',
                  textDecoration: 'none',
                }}
                className="w-100 py-2"
                onClick={update}
              >
                Modifier
              </CButton>
            </CCol>
          </CRow>
        </CCard>
      )}
    </DefaultLayout>
  )
}

UpdateDemande.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
}

export default UpdateDemande
