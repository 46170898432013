import React from 'react'
import { useHistory } from 'react-router-dom'

function Users() {
  let { location } = useHistory()

  return (
    <div
      style={{
        color: `${location.pathname === '/users' ? '#2ACC96' : 'white'}`,
      }}
    >
      Utilisateurs
    </div>
  )
}

export default Users
