import React, { useEffect, useState } from 'react'
import {
  CAlert,
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
} from '@coreui/react'
import axios from 'axios'
import { Formik } from 'formik'
import { URL__API } from 'src/axios'
import DefaultLayout from 'src/layout/DefaultLayout'
import { PropTypes } from 'prop-types'
import * as Yup from 'yup'
import { withRouter } from 'react-router-dom'
import Loading from 'src/components/Loading'
import './job.css'

function AddNewJobCat({ history, match }) {
  const [failed, setFailed] = useState('')
  const [jobs, setJobs] = useState([])
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    const fetchJobs = async () => {
      const res = await axios.get(`${URL__API}/user/jobs`)
      setJobs(res.data)
    }
    fetchJobs()
  }, [])

  const ajouter = async ({ label, priceEstimation, minPrice, job }) => {
    try {
      if (!job) {
        setChecked(true)
      } else {
        const res = await axios.post(
          `${URL__API}/jobs/${job}/categories`,
          {
            label,
            priceEstimation,
            minPrice,
            job,
          },
          {
            headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
          },
        )
        if (res.status === 201) {
          history.push('/métier')
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const validationSchema = Yup.object().shape({
    label: Yup.string().required('Veuillez renseigner ce champ'),
    priceEstimation: Yup.number().required('Veuillez renseigner ce champ'),
    minPrice: Yup.number().required('Veuillez renseigner ce champ'),
  })

  return (
    <DefaultLayout>
      <div className="job">
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            label: '',
            priceEstimation: '',
            minPrice: '',
            job: '',
          }}
          onSubmit={ajouter}
        >
          {({ values, touched, errors, handleChange, handleSubmit, setFieldValue }) => (
            <CForm className="job_new" onSubmit={handleSubmit}>
              <CRow className="job_add_field">
                <CCol xs>
                  <CFormLabel>Nom du sous-categorie</CFormLabel>
                  <CFormInput
                    placeholder="Sous-categorie"
                    type="text"
                    name="label"
                    value={values.label}
                    onChange={handleChange}
                  />
                  {errors.label && touched.label && <p style={{ color: 'red' }}>{errors.label}</p>}
                </CCol>
              </CRow>
              <CRow className="job_add_field">
                <CCol xs>
                  <CFormLabel>Prix du service</CFormLabel>
                  <CFormInput
                    placeholder="Prix du service"
                    type="number"
                    name="priceEstimation"
                    value={values.priceEstimation}
                    onChange={handleChange}
                  />
                  {errors.priceEstimation && touched.priceEstimation && (
                    <p style={{ color: 'red' }}>{errors.priceEstimation}</p>
                  )}
                </CCol>
              </CRow>

              <CRow className="job_add_field">
                <CCol xs>
                  <CFormLabel>Prix minimum</CFormLabel>
                  <CFormInput
                    placeholder="Prix minimum"
                    type="number"
                    name="minPrice"
                    value={values.minPrice}
                    onChange={handleChange}
                  />
                  {errors.minPrice && touched.minPrice && (
                    <p style={{ color: 'red' }}>{errors.minPrice}</p>
                  )}
                </CCol>
              </CRow>

              <CRow className="job_add_field">
                <CCol xs>
                  <CFormSelect value={values.job} onChange={handleChange} name="job">
                    <option>Sélectionner un métier</option>
                    {jobs.map((job) => (
                      <option className="py-2" key={job.id} value={job.id}>
                        {job.name}
                      </option>
                    ))}
                  </CFormSelect>
                  {checked && <p style={{ color: 'red' }}>Veuillez renseigner ce champ</p>}
                </CCol>
              </CRow>

              <CRow className="job_add_field">
                <CCol xs>
                  <CButton type="submit" className="w-100 py-2 job_btn">
                    Ajouter
                  </CButton>
                </CCol>
              </CRow>
              {/* {fail && (
                <CAlert
                  style={{
                    marginTop: '10px',
                  }}
                  color="danger"
                >
                  Ce metrier existe déjà
                </CAlert>
              )} */}
            </CForm>
          )}
        </Formik>
      </div>
    </DefaultLayout>
  )
}

AddNewJobCat.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
}

export default AddNewJobCat
