import React from 'react'
import { useHistory } from 'react-router-dom'

function Comptes() {
  let { location } = useHistory()

  return (
    <div
      style={{
        color: `${location.pathname === '/compte' ? '#2ACC96' : 'white'}`,
      }}
    >
      Compte
    </div>
  )
}

export default Comptes
