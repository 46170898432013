import React, { useState } from 'react'
import { CAlert, CButton, CCard, CCol, CForm, CFormInput, CFormLabel, CRow } from '@coreui/react'
import axios from 'axios'
import { Formik } from 'formik'
import { URL__API } from 'src/axios'
import DefaultLayout from 'src/layout/DefaultLayout'
import { PropTypes } from 'prop-types'
import * as Yup from 'yup'
import { withRouter } from 'react-router-dom'

function AddVariable({ history }) {
  const [fail, setFailValue] = useState(false)

  const ajouter = async ({ label, value }) => {
    try {
      await axios.post(
        `${URL__API}/variables`,
        { label, value },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      history.push('/variables')
    } catch (error) {
      if (error) {
        setFailValue(true)
      }
      console.error(error)
    }
  }

  const validationSchema = Yup.object().shape({
    label: Yup.string().required('Veuillez renseigner ce champ'),
    value: Yup.string().required('Veuillez renseigner ce champ'),
  })

  return (
    <DefaultLayout>
      <CCard>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            label: '',
            value: '',
          }}
          onSubmit={ajouter}
        >
          {({ values, touched, errors, handleChange, handleSubmit, setFieldValue }) => (
            <CForm className="w-75 mt-5 mx-auto" onSubmit={handleSubmit}>
              <CRow className="mx-5">
                <CCol xs>
                  <CFormLabel>Label</CFormLabel>
                  <CFormInput
                    placeholder="label"
                    type="text"
                    name="label"
                    value={values.label}
                    onChange={handleChange}
                  />
                  {errors.label && touched.label && <p style={{ color: 'red' }}>{errors.label}</p>}
                </CCol>
              </CRow>
              <CRow className="m-5">
                <CCol xs>
                  <CFormLabel>Valeur</CFormLabel>
                  <CFormInput
                    placeholder="valeur"
                    value={values.value}
                    type="text"
                    name="value"
                    onChange={handleChange}
                  />
                  {errors.value && touched.value && <p style={{ color: 'red' }}>{errors.value}</p>}
                </CCol>
              </CRow>

              <CRow className="m-5">
                <CCol xs>
                  <CButton
                    type="submit"
                    style={{
                      color: 'white',
                      background: '#2ACC96',
                      border: '#2ACC96',
                      textDecoration: 'none',
                    }}
                    className="w-100 py-2"
                  >
                    Ajouter
                  </CButton>
                </CCol>
              </CRow>
              {fail && (
                <CAlert
                  style={{
                    marginTop: '10px',
                  }}
                  color="danger"
                >
                  Ce variable existe déjà
                </CAlert>
              )}
            </CForm>
          )}
        </Formik>
      </CCard>
    </DefaultLayout>
  )
}

AddVariable.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default withRouter(AddVariable)
