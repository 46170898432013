import React, { useEffect, useState } from 'react'
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormCheck,
  CFormInput,
  CFormSelect,
  CHeader,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import { useLocation } from 'react-router-dom'
import DefaultLayout from 'src/layout/DefaultLayout'
import { URL__API } from 'src/axios'
import axios from 'axios'
import Loading from 'src/components/Loading'
import Paginated from 'src/components/paginated/Paginated'
import { cilPen, cilWarning, cilX } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { format, parseISO } from 'date-fns'
import { Link } from 'react-router-dom'
import * as toastr from 'toastr'
import 'toastr/build/toastr.css'
import './demande.css'

function Demande() {
  const queryParams = useLocation().search

  const [offers, setOffers] = useState([])
  const [searchOffers, setSearchOffers] = useState([])
  const [offerId, setOfferId] = useState('')
  const [visible, setVisible] = useState(false)
  const [jobs, setJobs] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [empty, setEmpty] = useState(false)
  const [filters, setFilters] = useState({
    time: '',
    state: '',
    job: '',
    city: '',
  })

  useEffect(() => {
    fetchOffers()
  }, [])
  const fetchOffers = async () => {
    const res = await axios.get(`${URL__API}/offers`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    setOffers(res.data)
  }

  const fetchCities = async () => {
    const res = await axios.get(`${URL__API}/cities`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    setCities(res.data)
  }

  const fetchStates = async () => {
    const res = await axios.get(`${URL__API}/offers/states`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })

    setStates(res.data)
  }

  useEffect(() => {
    const fetchJobs = async () => {
      const res = await axios.get(`${URL__API}/user/jobs`)
      setJobs(res.data)
    }
    fetchJobs()
    fetchStates()
    fetchCities()
  }, [])

  const handleSearch = async (text) => {
    if (text.length === 0) {
      fetchOffers()
      return
    }
    const res = await axios.get(`${URL__API}/offers/${text}/search`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    if (res.data.length === 0) return
    setOffers(res.data)
  }

  const handleAccept = async (id) => {
    setVisible(true)
    if (visible === true) {
      await axios.delete(`${URL__API}/offers/remove/${id}`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      setVisible(false)
      setOfferId('')
      fetchOffers()
    }
  }

  // const filterByTime = (value) => {
  // else if (value === '3') {
  //   const start = date.getDate() - 1
  //   const end = sub(new Date(), {
  //     days: 30,
  //   })
  //   console.log(end)
  //   console.log(
  //     eachMonthOfInterval({
  //       start: end,
  //       end: new Date(),
  //     }),
  //   )
  // console.log(+format(parseISO(end), 'd'))
  // setOffers(
  //   offers.filter((offer) => {
  //     return (
  //       +format(parseISO(offer.createdAt), 'd') < start &&
  //       +format(parseISO(offer.createdAt), 'd') >= end.getDate()
  //     )
  //   }),
  // )
  // }
  // }

  const filter = async () => {
    try {
      const params = new URLSearchParams(queryParams)
      params.set('time', filters.time)
      params.set('job', filters.job)
      params.set('state', filters.state)
      params.set('city', filters.city)
      const time = params.get('time')
      const job = params.get('job')
      const state = params.get('state')
      const city = params.get('city')

      if (city && job && state && time) {
        const res = await axios.get(
          `${URL__API}/offers/filters?job=${job}&status=${state}&time=${time}&city=${city}`,
          {
            headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
          },
        )

        if (res.data.length === 0) {
          setEmpty(true)
        } else {
          setEmpty(false)
        }
        setSearchOffers(res.data)
      } else if (job && state && time) {
        const res = await axios.get(
          `${URL__API}/offers/filters?job=${job}&status=${state}&time=${time}`,
          {
            headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
          },
        )

        if (res.data.status === 400 || res.data.length === 0) {
          setEmpty(true)
        } else {
          setEmpty(false)
        }
        setSearchOffers(res.data)
      } else if (city && state && time) {
        const res = await axios.get(
          `${URL__API}/offers/filters?city=${city}&status=${state}&time=${time}`,
          {
            headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
          },
        )

        if (res.data.status === 400 || res.data.length === 0) {
          setEmpty(true)
        } else {
          setEmpty(false)
        }
        setSearchOffers(res.data)
      } else if (city && job && time) {
        const res = await axios.get(
          `${URL__API}/offers/filters?city=${city}&job=${job}&time=${time}`,
          {
            headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
          },
        )

        if (res.data.status === 400 || res.data.length === 0) {
          setEmpty(true)
        } else {
          setEmpty(false)
        }
        setSearchOffers(res.data)
      } else if (city && job && state) {
        const res = await axios.get(
          `${URL__API}/offers/filters?city=${city}&job=${job}&status=${state}`,
          {
            headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
          },
        )

        if (res.data.status === 400 || res.data.length === 0) {
          setEmpty(true)
        } else {
          setEmpty(false)
        }
        setSearchOffers(res.data)
      } else if (city && time) {
        const res = await axios.get(`${URL__API}/offers/filters?city=${city}&time=${time}`, {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        })

        if (res.data.status === 400 || res.data.length === 0) {
          setEmpty(true)
        } else {
          setEmpty(false)
        }
        setSearchOffers(res.data)
      } else if (job && time) {
        const res = await axios.get(`${URL__API}/offers/filters?job=${job}&time=${time}`, {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        })

        if (res.data.status === 400 || res.data.length === 0) {
          setEmpty(true)
        } else {
          setEmpty(false)
        }
        setSearchOffers(res.data)
      } else if (state && time) {
        const res = await axios.get(`${URL__API}/offers/filters?status=${state}&time=${time}`, {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        })

        if (res.data.status === 400 || res.data.length === 0) {
          setEmpty(true)
        } else {
          setEmpty(false)
        }
        setSearchOffers(res.data)
      } else if (state && job) {
        const res = await axios.get(`${URL__API}/offers/filters?status=${state}&job=${job}`, {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        })

        if (res.data.status === 400 || res.data.length === 0) {
          setEmpty(true)
        } else {
          setEmpty(false)
        }
        setSearchOffers(res.data)
      } else if (state && city) {
        const res = await axios.get(`${URL__API}/offers/filters?status=${state}&city=${city}`, {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        })

        if (res.data.status === 400 || res.data.length === 0) {
          setEmpty(true)
        } else {
          setEmpty(false)
        }
        setSearchOffers(res.data)
      } else if (job && city) {
        const res = await axios.get(`${URL__API}/offers/filters?job=${job}&city=${city}`, {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        })

        if (res.data.status === 400 || res.data.length === 0) {
          setEmpty(true)
        } else {
          setEmpty(false)
        }
        setSearchOffers(res.data)
      } else if (time) {
        const res = await axios.get(`${URL__API}/offers/filters?time=${time}`, {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        })

        if (res.data.status === 400 || res.data.length === 0) {
          setEmpty(true)
        } else {
          setEmpty(false)
        }
        setSearchOffers(res.data)
      } else if (job) {
        const res = await axios.get(`${URL__API}/offers/filters?job=${job}`, {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        })

        if (res.data.status === 400 || res.data.length === 0) {
          setEmpty(true)
        } else {
          setEmpty(false)
        }
        setSearchOffers(res.data)
      } else if (state) {
        const res = await axios.get(`${URL__API}/offers/filters?status=${state}`, {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        })
        if (res.data.status === 400 || res.data.length === 0) {
          setEmpty(true)
        } else {
          setEmpty(false)
        }
        setSearchOffers(res.data)
      } else if (city) {
        console.log({ city })
        const res = await axios.get(`${URL__API}/offers/filters?city=${city}`, {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        })
        if (res.data.status === 400 || res.data.res.length === 0) {
          setEmpty(true)
        } else {
          setEmpty(false)
        }
        setSearchOffers(res.data.res)
      } else if (!city || !state || !job || !time) {
        fetchOffers()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <DefaultLayout>
      <h1>Demandes</h1>
      {offers.length === 0 ? (
        <Loading />
      ) : (
        <>
          <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
              <CModalTitle>Supprimer</CModalTitle>
            </CModalHeader>
            <CModalBody>Etes-vous sûr de supprimer ce demande ?</CModalBody>
            <CModalFooter>
              <CButton color="secondary" onClick={() => setVisible(false)}>
                Non
              </CButton>
              <CButton color="primary" onClick={() => handleAccept(offerId)}>
                Oui
              </CButton>
            </CModalFooter>
          </CModal>
          <Paginated items={searchOffers.length > 0 ? searchOffers : offers}>
            {(paginatedOffers) => (
              <CCol xs>
                <CCard className="mb-4">
                  <CHeader className="mr-4 offer_header" style={{ flexWrap: 'inherit' }}>
                    <CCardHeader>Demandes</CCardHeader>
                    <CRow>
                      <CCol className="mx-5 offer_col" xs>
                        <CCol xs="2" className="offer_filter">
                          <label>Temps:</label>
                          <CFormSelect
                            onChange={(e) => {
                              setFilters({ ...filters, time: e.currentTarget.value })
                            }}
                            options={[
                              { value: 0, label: 'Tous' },
                              { value: "aujourd'hui", label: "Aujourd'hui" },
                              { value: 'semaine', label: 'Semaine' },
                            ]}
                          />
                        </CCol>
                        <CCol className="mx-3 offer_filter" xs="3">
                          <label>Etat:</label>
                          <CFormSelect
                            width={15}
                            name="state"
                            onChange={(e) => {
                              setFilters({
                                ...filters,
                                state:
                                  e.currentTarget.value === 'Sélectionner une etat'
                                    ? ''
                                    : e.currentTarget.value,
                              })
                            }}
                          >
                            <option>Sélectionner une etat</option>
                            {states.map((state) => (
                              <option className="mx-2" key={state.id} value={state.id}>
                                {state.label}
                              </option>
                            ))}
                          </CFormSelect>
                        </CCol>
                        <CCol xs="3" className="offer_filter">
                          <label>Métier:</label>
                          <CFormSelect
                            width={15}
                            name="job"
                            onChange={(e) => {
                              setFilters({
                                ...filters,
                                job:
                                  e.currentTarget.value === 'Sélectionner un emploi'
                                    ? ''
                                    : e.currentTarget.value,
                              })
                            }}
                          >
                            <option>Sélectionner un emploi</option>
                            {jobs.map((job) => (
                              <option className="py-2" key={job.id} value={job.id}>
                                {job.name}
                              </option>
                            ))}
                          </CFormSelect>
                        </CCol>
                        <CRow xs="3" className="mx-3 offer_filter">
                          <label>Ville:</label>
                          <CFormSelect
                            width={15}
                            name="city"
                            onChange={(e) => {
                              setFilters({
                                ...filters,
                                city:
                                  e.currentTarget.value === 'Sélectionner une ville'
                                    ? ''
                                    : e.currentTarget.value,
                              })
                            }}
                          >
                            <option>Sélectionner une ville</option>
                            {cities
                              .sort((a, b) => a.id - b.id)
                              .map((city) => (
                                <option className="py-2" key={city.id} value={city.id}>
                                  {city.label}
                                </option>
                              ))}
                          </CFormSelect>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CCol xs="1" className="mt-4 mb-3 offer_filter_btn">
                      <CButton onClick={filter} className="offer_filter_btn">
                        Filtrer
                      </CButton>
                    </CCol>
                    <CCol xs="5" className="w-25 offer_filter">
                      <label>Recherche:</label>
                      <CFormInput
                        type="text"
                        name="text"
                        placeholder="Rechercher ..."
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                    </CCol>
                  </CHeader>
                  {!empty ? (
                    <CCardBody>
                      <br />
                      <CTable align="middle" className="mb-0 border" hover responsive>
                        <CTableHead color="light">
                          <CTableRow>
                            <CTableHeaderCell className="text-center">Référence</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Ville</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Métier</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Date</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Temps</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Etat</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Actions</CTableHeaderCell>
                          </CTableRow>
                        </CTableHead>

                        <CTableBody>
                          {paginatedOffers &&
                            paginatedOffers.map((item, index) => (
                              <CTableRow
                                v-for="item in tableItems"
                                className="text-center"
                                key={index}
                              >
                                <CTableDataCell className="text-center">
                                  {item.offer_ref !== null ? (
                                    <div>{item.offer_ref}</div>
                                  ) : (
                                    <div>ref</div>
                                  )}
                                </CTableDataCell>
                                <CTableDataCell className="text-center">
                                  {item.city && <div>{item.city.label}</div>}
                                </CTableDataCell>
                                <CTableDataCell className="text-center">
                                  {item.job !== null && <div>{item.job.name}</div>}
                                </CTableDataCell>
                                <CTableDataCell>
                                  <span>{format(new Date(item.createdAt), 'dd/MM/yyyy')}</span>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>{item.hour}</div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div
                                    style={{
                                      color: `${
                                        (item.state.label === 'Annulée' && '#FC376B') ||
                                        (item.state.label === 'Terminée' && '#2ACC96') ||
                                        (item.state.label === 'Publiée' && '#29129D') ||
                                        (item.state.label === 'En cours' && '#9316F5') ||
                                        (item.state.label === 'En attente' && '#FF9A03')
                                      }`,
                                    }}
                                  >
                                    {item.state.label}
                                  </div>
                                </CTableDataCell>
                                <CTableDataCell className="text-center">
                                  <Link to={`/demande/${item.id}`}>
                                    <CIcon
                                      icon={cilPen}
                                      title="Modifie"
                                      style={{
                                        color: '#2ACC96',
                                        border: '2px solid #2ACC96',
                                        borderRadius: '100%',
                                        margin: '9px',
                                        cursor: 'pointer',
                                      }}
                                      size="xl"
                                    />
                                  </Link>
                                  <CIcon
                                    title="supprimer"
                                    icon={cilX}
                                    style={{
                                      cursor: 'pointer',
                                      color: 'red',
                                      border: '2px solid red',
                                      borderRadius: '100%',
                                      padding: '1px',
                                      margin: '9px',
                                    }}
                                    size="xl"
                                    onClick={() => {
                                      setVisible(true)
                                      setOfferId(item.id)
                                    }}
                                  />
                                </CTableDataCell>
                              </CTableRow>
                            ))}
                        </CTableBody>
                      </CTable>
                    </CCardBody>
                  ) : (
                    <CAlert
                      color="warning"
                      className="d-flex align-items-center w-100 mx-auto justify-content-center "
                    >
                      <CIcon
                        icon={cilWarning}
                        className="flex-shrink-0 me-2"
                        width={24}
                        height={24}
                      />
                      <div>Les offres que vous voulez nexistent pas</div>
                    </CAlert>
                  )}
                </CCard>
              </CCol>
            )}
          </Paginated>
        </>
      )}
    </DefaultLayout>
  )
}

export default Demande
