import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { URL__API } from 'src/axios'
import DefaultLayout from 'src/layout/DefaultLayout'
import { PropTypes } from 'prop-types'
import * as Yup from 'yup'
import './bricoleur.css'

const AddBricoleurs = ({ history }) => {
  const [jobs, setJobs] = useState([])
  const [checked, setChecked] = useState(false)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const fetchJobs = async () => {
      const res = await axios.get(`${URL__API}/user/jobs`)
      setJobs(res.data)
    }
    fetchJobs()
  }, [])

  const register = async ({ firstname, lastname, password, email, phone, job, role = '3' }) => {
    try {
      setVisible(true)
      if (visible) {
        if (!job) {
          setChecked(true)
        } else {
          const res = await axios.post(`${URL__API}/user/register`, {
            firstname,
            lastname,
            email,
            password,
            phone,
            job,
            role,
          })
          if (res.status === 201) {
            setVisible(false)
            history.push('/bricoleurs')
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Veuillez renseigner ce champ'),
    lastname: Yup.string().required('Veuillez renseigner ce champ'),
    password: Yup.string().required('Veuillez renseigner ce champ'),
    email: Yup.string().email("Format d'email invalide").required('Veuillez renseigner ce champ'),
    phone: Yup.string().required('Veuillez renseigner ce champ'),
  })

  return (
    <DefaultLayout>
      <div className="">
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            firstname: '',
            lastname: '',
            password: '',
            email: '',
            phone: '',
          }}
          onSubmit={register}
        >
          {({ values, touched, errors, handleChange, handleSubmit }) => (
            <CForm className="w-75 mt-5 mx-auto" onSubmit={handleSubmit}>
              <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
                <CModalHeader>
                  <CModalTitle>Ajouter</CModalTitle>
                </CModalHeader>
                <CModalBody>Etes-vous sûr d&apos;ajouter ce bricoleur ?</CModalBody>
                <CModalFooter>
                  <CButton color="danger" onClick={() => setVisible(false)}>
                    Non
                  </CButton>
                  <CButton color="success" onClick={() => register(values)}>
                    Oui
                  </CButton>
                </CModalFooter>
              </CModal>
              <CRow className="new__bricoleur my-5">
                <CCol xs className="col_brico">
                  <CFormLabel>Nom</CFormLabel>
                  <CFormInput
                    placeholder="Nom"
                    type="text"
                    name="firstname"
                    value={values.firstName}
                    onChange={handleChange}
                  />
                  {errors.firstName && touched.firstName && (
                    <p style={{ color: 'red' }}>{errors.firstName}</p>
                  )}
                </CCol>
                <CCol xs className="col_brico">
                  <CFormLabel>Prénom</CFormLabel>
                  <CFormInput
                    placeholder="Prénom"
                    type="text"
                    name="lastname"
                    value={values.lastname}
                    onChange={handleChange}
                  />
                  {errors.lastname && touched.lastname && (
                    <p style={{ color: 'red' }}>{errors.lastname}</p>
                  )}
                </CCol>
              </CRow>
              <CRow className=" new__bricoleur my-5">
                <CCol xs className="col_brico">
                  <CFormLabel>Email</CFormLabel>
                  <CFormInput
                    placeholder="Email"
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  {errors.email && touched.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                </CCol>
                <CCol xs className="col_brico">
                  <CFormLabel>Mot de passe</CFormLabel>
                  <CFormInput
                    placeholder="Mot de passe"
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                  {errors.password && touched.password && (
                    <p style={{ color: 'red' }}>{errors.password}</p>
                  )}
                </CCol>
              </CRow>
              <CRow className=" new__bricoleur my-5">
                <CCol xs className="col_brico">
                  <CFormLabel>Téléphone</CFormLabel>
                  <CFormInput
                    placeholder="Téléphone"
                    type="text"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                  />
                  {errors.phone && touched.phone && <p style={{ color: 'red' }}>{errors.phone}</p>}
                </CCol>
                <CCol xs className="col_brico">
                  <CFormLabel>Emploi</CFormLabel>
                  <CFormSelect value={values.job} onChange={handleChange} name="job">
                    <option>Sélectionner un emploi</option>
                    {jobs.map((job) => (
                      <option className="py-2" key={job.id} value={job.id}>
                        {job.name}
                      </option>
                    ))}
                  </CFormSelect>
                  {checked && <p style={{ color: 'red' }}>Veuillez renseigner ce champ</p>}
                </CCol>
              </CRow>

              <CRow className="my-5 me-2">
                <CCol xs className="col_brico">
                  <CButton
                    type="submit"
                    style={{
                      color: 'white',
                      background: '#2ACC96',
                      border: '#2ACC96',
                      textDecoration: 'none',
                    }}
                    className="w-100 py-2"
                    onClick={register}
                  >
                    Ajouter
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          )}
        </Formik>
      </div>
    </DefaultLayout>
  )
}

AddBricoleurs.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default AddBricoleurs
