import React, { useEffect, useState } from 'react'
import {
  CAlert,
  CButton,
  CCard,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
} from '@coreui/react'
import axios from 'axios'
import { Formik } from 'formik'
import { URL__API } from 'src/axios'
import DefaultLayout from 'src/layout/DefaultLayout'
import { PropTypes } from 'prop-types'
import * as Yup from 'yup'
import { withRouter } from 'react-router-dom'
import { nanoid } from 'nanoid'

function UpdateCode({ history, match }) {
  const [code, setCode] = useState(null)
  const [fail, setFailValue] = useState(false)

  const validationSchema = Yup.object().shape({
    value: Yup.number().required('Veuillez renseigner ce champ'),
    max: Yup.number().required('Veuillez renseigner ce champ'),
  })

  useEffect(() => {
    getOneCode()
  }, [])

  const getOneCode = async () => {
    try {
      const res = await axios.get(`${URL__API}/codepromos/${match.params.id}`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      setCode(res.data)
    } catch (err) {
      console.error(err)
    }
  }

  const update = async ({ value, max, type }) => {
    try {
      const res = await axios.patch(
        `${URL__API}/codepromos/${match.params.id}`,
        { value: parseInt(value), max: parseInt(max), type },
        {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      )
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <DefaultLayout>
      {code && (
        <CCard>
          <Formik validationSchema={validationSchema} initialValues={code} onSubmit={update}>
            {({ values, touched, errors, handleChange, handleSubmit }) => (
              <CForm className="w-75 mt-5 mx-auto" onSubmit={handleSubmit}>
                <CRow className="m-5">
                  <CCol xs>
                    <CFormLabel>Valeur</CFormLabel>
                    <CFormInput
                      placeholder="valeur"
                      value={values?.value}
                      type="text"
                      name="value"
                      onChange={handleChange}
                    />
                    {errors.value && touched.value && (
                      <p style={{ color: 'red' }}>{errors.value}</p>
                    )}
                  </CCol>
                </CRow>

                <CRow className="m-5">
                  <CCol xs>
                    <CFormLabel>Nombre d&apos;utilisation</CFormLabel>
                    <CFormInput
                      placeholder="Nombre d'utilisation"
                      value={values?.max}
                      type="text"
                      name="max"
                      onChange={handleChange}
                    />
                    {errors.max && touched.max && <p style={{ color: 'red' }}>{errors.max}</p>}
                  </CCol>
                </CRow>
                <CRow className="m-5">
                  <CCol xs>
                    <label className="mb-3">Le type:</label>
                    <CFormSelect
                      value={values?.type}
                      name="type"
                      onChange={handleChange}
                      options={[
                        { value: 0, label: 'Selectionner une type' },
                        { value: 'P', label: 'Pourcentage' },
                        { value: 'M', label: 'Mountant' },
                      ]}
                    />
                  </CCol>
                </CRow>

                <CRow className="m-5">
                  <CCol xs>
                    <CButton
                      type="submit"
                      style={{
                        color: 'white',
                        background: '#2ACC96',
                        border: '#2ACC96',
                        textDecoration: 'none',
                      }}
                      className="w-100 py-2"
                    >
                      Modifier
                    </CButton>
                  </CCol>
                </CRow>
                {fail && (
                  <CAlert
                    style={{
                      marginTop: '10px',
                    }}
                    color="danger"
                  >
                    Cette code existe déjà
                  </CAlert>
                )}
              </CForm>
            )}
          </Formik>
        </CCard>
      )}
    </DefaultLayout>
  )
}

UpdateCode.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
}

export default UpdateCode
