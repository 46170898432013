import React from 'react'
import { useHistory } from 'react-router-dom'

function Demandes() {
  let { location } = useHistory()

  return (
    <div
      style={{
        color: `${location.pathname === '/demandes' ? '#2ACC96' : 'white'}`,
      }}
    >
      Demandes
    </div>
  )
}

export default Demandes
