import React from 'react'
import { useHistory } from 'react-router-dom'

function Chat() {
  let { location } = useHistory()

  return (
    <>
      <svg
        width="25"
        height="32"
        style={{ marginRight: '18px', marginLeft: '5px' }}
        viewBox="0 0 50 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="50" height="48.9529" fill="#FF0000" fillOpacity="0.01" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.8173 41.1865C30.4231 42.1468 27.7788 42.6834 25 42.6834C13.9519 42.6834 5 34.2296 5 23.7894C5 13.3587 13.9519 4.89551 25 4.89551C36.0481 4.89551 45 13.3587 44.9808 23.8083C44.9808 27.5645 43.8173 31.0665 41.8077 34.0131C41.7212 34.1166 41.6442 34.239 41.5673 34.3614C41.3365 34.7662 41.1923 35.2369 41.1923 35.7358L43.3173 44.0578L35.2404 41.1489C34.8846 40.9983 34.4904 40.9229 34.0769 40.9229C33.7019 40.9229 33.3365 40.9888 33 41.1112C33 41.1206 32.9904 41.1206 32.9808 41.1206C32.9231 41.1395 32.875 41.1583 32.8173 41.1865ZM37.3077 24.4767C37.3077 22.8104 35.9327 21.4642 34.2308 21.4642C32.5288 21.4642 31.1538 22.8104 31.1538 24.4767C31.1538 26.1429 32.5288 27.4891 34.2308 27.4891C35.9327 27.4891 37.3077 26.1429 37.3077 24.4767ZM25 21.4642C26.7019 21.4642 28.0769 22.8104 28.0769 24.4767C28.0769 26.1429 26.7019 27.4891 25 27.4891C23.2981 27.4891 21.9231 26.1429 21.9231 24.4767C21.9231 22.8104 23.2981 21.4642 25 21.4642ZM18.8462 24.4767C18.8462 22.8104 17.4712 21.4642 15.7692 21.4642C14.0673 21.4642 12.6923 22.8104 12.6923 24.4767C12.6923 26.1429 14.0673 27.4891 15.7692 27.4891C17.4712 27.4891 18.8462 26.1429 18.8462 24.4767Z"
          fill={`${location.pathname === '/messages' ? '#2ACC96' : 'white'}`}
        />
        <mask
          id="mask0_5397_7239"
          // style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="5"
          y="4"
          width="40"
          height="41"
        >
          <path
            fillRule="evenodd"
            clipule="evenodd"
            d="M32.8173 41.1865C30.4231 42.1468 27.7788 42.6834 25 42.6834C13.9519 42.6834 5 34.2296 5 23.7894C5 13.3587 13.9519 4.89551 25 4.89551C36.0481 4.89551 45 13.3587 44.9808 23.8083C44.9808 27.5645 43.8173 31.0665 41.8077 34.0131C41.7212 34.1166 41.6442 34.239 41.5673 34.3614C41.3365 34.7662 41.1923 35.2369 41.1923 35.7358L43.3173 44.0578L35.2404 41.1489C34.8846 40.9983 34.4904 40.9229 34.0769 40.9229C33.7019 40.9229 33.3365 40.9888 33 41.1112C33 41.1206 32.9904 41.1206 32.9808 41.1206C32.9231 41.1395 32.875 41.1583 32.8173 41.1865ZM37.3077 24.4767C37.3077 22.8104 35.9327 21.4642 34.2308 21.4642C32.5288 21.4642 31.1538 22.8104 31.1538 24.4767C31.1538 26.1429 32.5288 27.4891 34.2308 27.4891C35.9327 27.4891 37.3077 26.1429 37.3077 24.4767ZM25 21.4642C26.7019 21.4642 28.0769 22.8104 28.0769 24.4767C28.0769 26.1429 26.7019 27.4891 25 27.4891C23.2981 27.4891 21.9231 26.1429 21.9231 24.4767C21.9231 22.8104 23.2981 21.4642 25 21.4642ZM18.8462 24.4767C18.8462 22.8104 17.4712 21.4642 15.7692 21.4642C14.0673 21.4642 12.6923 22.8104 12.6923 24.4767C12.6923 26.1429 14.0673 27.4891 15.7692 27.4891C17.4712 27.4891 18.8462 26.1429 18.8462 24.4767Z"
            fill={`${location.pathname === '/messages' ? '#2ACC96' : 'white'}`}
          />
        </mask>
        <g mask="url(#mask0_5397_7239)">
          <rect
            width="50"
            height="48.9529"
            fill={`${location.pathname === '/messages' ? '#2ACC96' : 'white'}`}
          />
        </g>
      </svg>
    </>
  )
}

export default Chat
