import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import axios from 'axios'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { URL__API } from 'src/axios'
import DefaultLayout from 'src/layout/DefaultLayout'
import { PropTypes } from 'prop-types'
import * as Yup from 'yup'
import Loading from 'src/components/Loading'
import * as toastr from 'toastr'
import 'toastr/build/toastr.css'

const UpdateUsers = ({ history, match }) => {
  const [user, setUser] = useState(null)
  const [failed, setFailed] = useState(false)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    try {
      const fetchBricoleur = async () => {
        const res = await axios.get(`${URL__API}/user/profile/${match.params.id}`, {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        })
        if (res.status !== 200 || res.status === 404) {
          setFailed(true)
        }

        setUser(res.data)
      }
      fetchBricoleur()
    } catch (error) {
      setFailed(true)
      console.log(error)
    }
  }, [match.params.id])

  const update = async ({ firstName, lastname, password, email, phone, job, role = '3' }) => {
    try {
      setVisible(true)
      if (visible) {
        const res = await axios.put(
          `${URL__API}/user/update/${match.params.id}`,
          {
            firstName,
            lastname,
            phone,
            job,
            type: 'avatar',
          },
          {
            headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
          },
        )

        if ([200, 201].includes(res.status)) {
          setVisible(false)
          toastr.success('Ce bricoleur est modifiée avec succès', {
            positionClass: 'toast-bottom-left',
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Veuillez renseigner ce champ'),
    lastname: Yup.string().required('Veuillez renseigner ce champ'),
    email: Yup.string().email("Format d'email invalide").required('Veuillez renseigner ce champ'),
    phone: Yup.string().required('Veuillez renseigner ce champ'),
  })

  return (
    <DefaultLayout>
      {failed || !user ? (
        <Loading />
      ) : (
        <div className="">
          <CButton color="secondary" onClick={() => history.push('/users')}>
            Retour
          </CButton>
          <Formik validationSchema={validationSchema} initialValues={user} onSubmit={update}>
            {({ values, touched, errors, handleChange, handleSubmit }) => (
              <CForm className="user_update" onSubmit={handleSubmit}>
                <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
                  <CModalHeader>
                    <CModalTitle>Modification</CModalTitle>
                  </CModalHeader>
                  <CModalBody>Etes-vous sûr de modifier cet utilisateur ?</CModalBody>
                  <CModalFooter>
                    <CButton color="danger" onClick={() => setVisible(false)}>
                      Non
                    </CButton>
                    <CButton color="success" onClick={() => update(values)}>
                      Oui
                    </CButton>
                  </CModalFooter>
                </CModal>
                <CRow className=" user_update_field">
                  <CCol xs>
                    <CFormLabel>Nom</CFormLabel>
                    <CFormInput
                      placeholder="Nom"
                      type="text"
                      name="firstName"
                      value={values?.firstName}
                      onChange={handleChange}
                    />
                    {errors.firstName && touched.firstName && (
                      <p style={{ color: 'red' }}>{errors.firstName}</p>
                    )}
                  </CCol>
                  <CCol xs>
                    <CFormLabel>Prénom</CFormLabel>
                    <CFormInput
                      placeholder="Prénom"
                      type="text"
                      name="lastname"
                      value={values?.lastname}
                      onChange={handleChange}
                    />
                    {errors.lastname && touched.lastname && (
                      <p style={{ color: 'red' }}>{errors.lastname}</p>
                    )}
                  </CCol>
                </CRow>

                <CRow className=" user_update_field">
                  <CCol xs>
                    <CFormLabel>Email</CFormLabel>
                    <CFormInput
                      placeholder="Email"
                      type="email"
                      name="email"
                      value={values?.email}
                      onChange={handleChange}
                    />
                    {errors.email && touched.email && (
                      <p style={{ color: 'red' }}>{errors.email}</p>
                    )}
                  </CCol>
                  <CCol xs>
                    <CFormLabel>Téléphone</CFormLabel>
                    <CFormInput
                      placeholder="Téléphone"
                      type="text"
                      name="phone"
                      value={values?.phone}
                      onChange={handleChange}
                    />
                    {errors.phone && touched.phone && (
                      <p style={{ color: 'red' }}>{errors.phone}</p>
                    )}
                  </CCol>
                </CRow>

                <CRow className=" user_update_field">
                  <CCol xs>
                    <CFormLabel>Addresse</CFormLabel>
                    <CFormInput
                      placeholder="Addresse"
                      type="text"
                      name="address"
                      value={values?.address}
                      onChange={handleChange}
                    />
                    {errors.address && touched.address && (
                      <p style={{ color: 'red' }}>{errors.address}</p>
                    )}
                  </CCol>
                  <CCol xs>
                    <CFormLabel>Ville</CFormLabel>
                    <CFormInput
                      placeholder="Ville"
                      type="text"
                      name="city"
                      value={values?.city?.label}
                      onChange={handleChange}
                    />
                    {errors.city && touched.city && <p style={{ color: 'red' }}>{errors.city}</p>}
                  </CCol>
                </CRow>

                <CRow className=" user_update_field">
                  <CCol xs>
                    <CFormLabel>Latitude</CFormLabel>
                    <CFormInput
                      placeholder="latitude"
                      type="text"
                      name="latitude"
                      value={values?.latitude}
                      onChange={handleChange}
                    />
                  </CCol>
                  <CCol xs>
                    <CFormLabel>Longitude</CFormLabel>
                    <CFormInput
                      placeholder="longitude"
                      type="text"
                      name="longitude"
                      value={values?.longitude}
                      onChange={handleChange}
                    />
                  </CCol>
                </CRow>

                <CRow className=" user_update_field">
                  <CCol xs>
                    <CFormLabel>Pays</CFormLabel>
                    <CFormInput
                      placeholder="Pays"
                      type="text"
                      name="country"
                      value={values?.country}
                      onChange={handleChange}
                    />
                  </CCol>
                  <CCol xs>
                    <CFormLabel>CIN</CFormLabel>
                    <CFormInput
                      placeholder="cin"
                      type="text"
                      name="cin"
                      value={values?.cin}
                      onChange={handleChange}
                    />
                  </CCol>
                </CRow>

                <CRow>
                  <CCol xs>
                    <CButton type="submit" className="user_btn">
                      Modifier
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>
            )}
          </Formik>
        </div>
      )}
    </DefaultLayout>
  )
}

UpdateUsers.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
}

export default UpdateUsers
