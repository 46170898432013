import React, { useEffect, useState } from 'react'
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormSelect,
  CHeader,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import { URL__API } from 'src/axios'
import axios from 'axios'
import avatar from '../../../assets/images/avatars/Avatars.png'
import { format } from 'date-fns'
import DefaultLayout from 'src/layout/DefaultLayout'
import Loading from 'src/components/Loading'
import Paginated from '../../../components/paginated/Paginated'
import { Link } from 'react-router-dom'
import CIcon from '@coreui/icons-react'
import {
  cilPen,
  cilX,
  cilPlus,
  cilExternalLink,
  cilSwapVertical,
  cilLockLocked,
  cilWarning,
} from '@coreui/icons'
import Verifie from 'src/components/Verifie'
import { PropTypes } from 'prop-types'
import * as toastr from 'toastr'
import 'toastr/build/toastr.css'
import blOck from '../../../assets/images/block.png'
import lock from '../../../assets/lock.png'
import unblock from '../../../assets/images/unblock.png'
import NoBricoleurs from '../nodata/NoBricoleurs'
import './bricoleur.css'

function Bricoleurs({ history }) {
  const [bricoleurs, setBricoleurs] = useState([])
  const [bricoleursSorted, setBricoleursSorted] = useState([])
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isDelete, setIsDelete] = useState(false)
  const [block, setBlock] = useState([])
  const [isBlock, setIsBlock] = useState([])
  const [bricoleurId, setBricoleurId] = useState('')
  const [empty, setEmpty] = useState(false)

  useEffect(() => {
    fetchBlockUsers()
    fetchData()
  }, [])

  const fetchData = async () => {
    const bricoleurs = await axios.get(`${URL__API}/user/all/bricoleurs`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })

    if ([200, 201].includes(bricoleurs.status)) {
      setLoading(false)
      setBricoleurs(bricoleurs.data)
    }
  }

  const fetchBlockUsers = async () => {
    const blocked = await axios.get(`${URL__API}/user/blocks`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })

    setBlock(blocked.data)
  }

  const handleDelete = async (id) => {
    setVisible(true)
    if (visible === true) {
      await axios.delete(`${URL__API}/user/delete/${id}`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      setVisible(false)
      setBricoleurId('')
      fetchData()
      toastr.success('Ce bricoleur est supprimé avec succès', {
        positionClass: 'toast-bottom-left',
      })
    }
  }

  const handleVerification = async (id) => {
    await axios.put(`${URL__API}/verification/identity/${id}`, null, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    fetchData()
  }

  const handleBlock = async (id) => {
    setVisible(true)

    if (visible === true) {
      try {
        const res = await axios.post(`${URL__API}/user/${id}/block`, null, {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        })
        if (res.data.status === 400) {
          setVisible(false)
          toastr.error(res?.data?.response?.message, {
            positionClass: 'toast-bottom-left',
          })
        } else {
          setVisible(false)
          setBricoleurId('')
          toastr.success('Ce bricoleur est bloqué avec succès', {
            positionClass: 'toast-bottom-left',
          })

          fetchData()
          fetchBlockUsers()
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleSearch = async (text) => {
    if (text.length === 0) {
      fetchData()
      return
    }
    const res = await axios.get(`${URL__API}/user/bricoleur/search/${text}`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    if (res.data.length === 0) return
    setBricoleurs(res.data)
  }

  const handleUnBlock = async (id) => {
    setVisible(true)
    if (visible === true) {
      try {
        await axios.post(`${URL__API}/user/${id}/unblock`, null, {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        })
        setVisible(false)
        setBricoleurId('')
        fetchData()
        fetchBlockUsers()

        toastr.success('Ce bricoleur est debloqué avec succès', {
          positionClass: 'toast-bottom-left',
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleFilter = async (val) => {
    try {
      if (bricoleurs.length > 0) {
        if (val === '1') {
          const res = await axios.get(`${URL__API}/user/filter/bricoleurs?val=3`, {
            headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
          })

          if (res.data.status === 400 || res.data.length === 0) {
            setEmpty(true)
          } else {
            setEmpty(false)
          }

          setBricoleursSorted(res.data)
        } else if (val === '2') {
          const res = await axios.get(`${URL__API}/user/filter/bricoleurs?val=4`, {
            headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
          })

          if (res.data.status === 400 || res.data.length === 0) {
            setEmpty(true)
          } else {
            setEmpty(false)
          }

          setBricoleursSorted(res.data)
        } else if (val === 'names') {
          const res = await axios.get(`${URL__API}/user/filter/bricoleurs?val=0`, {
            headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
          })

          if (res.data.status === 400 || res.data.length === 0) {
            setEmpty(true)
          } else {
            setEmpty(false)
          }

          setBricoleursSorted(res.data)
        } else if (val === 'email') {
          const res = await axios.get(`${URL__API}/user/filter/bricoleurs?val=1`, {
            headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
          })

          if (res.data.status === 400 || res.data.length === 0) {
            setEmpty(true)
          } else {
            setEmpty(false)
          }

          setBricoleursSorted(res.data)
        } else if (val === 'job') {
          const res = await axios.get(`${URL__API}/user/filter/bricoleurs?val=2`, {
            headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
          })

          if (res.data.status === 400 || res.data.length === 0) {
            setEmpty(true)
          } else {
            setEmpty(false)
          }

          setBricoleursSorted(res.data)
        } else if (val === '5') {
          const res = await axios.get(`${URL__API}/user/filter/bricoleurs?val=5`, {
            headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
          })

          if (res.data.status === 400 || res.data.length === 0) {
            setEmpty(true)
          } else {
            setEmpty(false)
          }

          setBricoleursSorted(res.data)
        } else if (val === '6') {
          const res = await axios.get(`${URL__API}/user/filter/bricoleurs?val=6`, {
            headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
          })

          if (res.data.status === 400 || res.data.length === 0) {
            setEmpty(true)
          } else {
            setEmpty(false)
          }

          setBricoleursSorted(res.data)
        } else {
          const res = await axios.get(`${URL__API}/user/filter/bricoleurs`, {
            headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
          })

          if (res.data.status === 400 || res.data.length === 0) {
            setEmpty(true)
          } else {
            setEmpty(false)
          }

          setBricoleursSorted(res.data)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  if (loading) {
    return (
      <DefaultLayout>
        <Loading />
      </DefaultLayout>
    )
  }

  return (
    <DefaultLayout>
      {bricoleurs.length === 0 ? (
        <NoBricoleurs msg="bricoleurs" />
      ) : (
        <>
          <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
              <CModalTitle>
                {' '}
                {isBlock ? 'Déblocage' : isDelete ? 'Suppression' : 'Blocage'}{' '}
              </CModalTitle>
            </CModalHeader>
            <CModalBody>
              {isBlock
                ? 'Etes-vous sûr de débloquer ce bricoleur ?'
                : ` Etes-vous sûr de ${isDelete ? 'supprimer' : 'bloquer'} ce bricoleur ? `}
            </CModalBody>
            <CModalFooter>
              <CButton color="secondary" onClick={() => setVisible(false)}>
                Non
              </CButton>
              <CButton
                color="primary"
                onClick={() => {
                  if (isDelete) {
                    handleDelete(bricoleurId)
                  } else if (isBlock) {
                    handleUnBlock(bricoleurId)
                  } else {
                    handleBlock(bricoleurId)
                  }
                }}
              >
                Oui
              </CButton>
            </CModalFooter>
          </CModal>
          <CRow>
            <Paginated items={bricoleursSorted.length > 0 ? bricoleursSorted : bricoleurs}>
              {(paginatedBricoleurs) => (
                <CCol xs>
                  <CCard className="mb-4">
                    <CHeader className="mr-4 bricoleur_header ">
                      <CCardHeader>Bricoleurs</CCardHeader>

                      <CCol xs="2" className="bricoleur_col">
                        <label>La vérification:</label>
                        <CFormSelect
                          onChange={(e) => handleFilter(e.target.value)}
                          options={[
                            { value: 0, label: 'Tous' },
                            { value: 1, label: 'Vérifié' },
                            { value: 2, label: 'Non vérifié' },
                          ]}
                        />
                      </CCol>
                      <CCol xs="2" className="bricoleur_col">
                        <label>Le blocage:</label>
                        <CFormSelect
                          onChange={(e) => handleFilter(e.target.value)}
                          options={[
                            { value: 0, label: 'Tous' },
                            { value: 5, label: 'Bloqué' },
                            { value: 6, label: 'Non bloqué' },
                          ]}
                        />
                      </CCol>

                      <CCol xs="6" className="w-25 bricoleur_col">
                        <CFormInput
                          type="text"
                          name="text"
                          placeholder="Rechercher ..."
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                      </CCol>
                      <Link
                        to="/addbricoleurs"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          color: 'white',
                          background: '#2ACC96',
                          border: '#2ACC96',
                          textDecoration: 'none',
                          borderRadius: '20px',
                          margin: '20px',
                          paddingRight: '25px',
                          paddingLeft: '25px',
                          paddingBottom: '16px',
                          paddingTop: '16px',
                        }}
                      >
                        <CIcon icon={cilPlus} size="lg" style={{ marginRight: '16px' }}></CIcon>
                        Ajouter un bricoleur
                      </Link>
                    </CHeader>
                    {!empty ? (
                      <CCardBody>
                        <br />
                        <CTable align="middle" className="mb-0 border" hover responsive>
                          <CTableHead color="light">
                            <CTableRow>
                              <CTableHeaderCell className="text-center"></CTableHeaderCell>
                              <CTableHeaderCell
                                className="text-center"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleFilter('names')}
                              >
                                Nom complet
                                <CIcon
                                  icon={cilSwapVertical}
                                  size="sm"
                                  style={{ marginLeft: '3px' }}
                                />
                              </CTableHeaderCell>
                              <CTableHeaderCell
                                className="text-center"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleFilter('email')}
                              >
                                Email
                                <CIcon
                                  icon={cilSwapVertical}
                                  size="sm"
                                  style={{ marginLeft: '3px' }}
                                />
                              </CTableHeaderCell>
                              <CTableHeaderCell
                                className="text-center"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleFilter('job')}
                              >
                                Travail
                                <CIcon
                                  icon={cilSwapVertical}
                                  size="sm"
                                  style={{ marginLeft: '3px' }}
                                />
                              </CTableHeaderCell>
                              <CTableHeaderCell
                                className="text-center"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleFilter('dateAt')}
                              >
                                Date d&apos;inscription
                                <CIcon
                                  icon={cilSwapVertical}
                                  size="sm"
                                  style={{ marginLeft: '3px' }}
                                />
                              </CTableHeaderCell>
                              <CTableHeaderCell className="text-center">Vérifié</CTableHeaderCell>
                              <CTableHeaderCell className="text-center">Actions</CTableHeaderCell>
                            </CTableRow>
                          </CTableHead>
                          <CTableBody>
                            {paginatedBricoleurs &&
                              paginatedBricoleurs.map((item, index) => (
                                <CTableRow
                                  v-for="item in tableItems"
                                  key={index}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <CTableDataCell
                                    className="text-center"
                                    onClick={() => {
                                      history.push(`/bricoleur/${item.id}`)
                                    }}
                                  >
                                    <div
                                      style={{ width: '4rem', height: '4rem', textAlign: 'center' }}
                                    >
                                      <img
                                        alt="avatar"
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                          borderRadius: '50%',
                                        }}
                                        src={item.avatar ? item.avatar : avatar}
                                      />
                                    </div>
                                  </CTableDataCell>
                                  <CTableDataCell
                                    className="text-center"
                                    onClick={() => {
                                      history.push(`/bricoleur/${item.id}/info`)
                                    }}
                                  >
                                    <div>
                                      {item.firstName + ' ' + item.lastname}
                                      <CIcon
                                        icon={cilExternalLink}
                                        size="sm"
                                        style={{ marginLeft: '3px' }}
                                      />
                                    </div>
                                  </CTableDataCell>
                                  <CTableDataCell className="text-center">
                                    <div className="small text-medium-emphasis">
                                      <span>{item.email}</span>
                                    </div>
                                  </CTableDataCell>

                                  <CTableDataCell className="text-center">
                                    <span> {item.job.name} </span>
                                  </CTableDataCell>
                                  <CTableDataCell className="text-center">
                                    <span>{format(new Date(item.updatedAt), 'MM/dd/yyyy')}</span>
                                  </CTableDataCell>
                                  <CTableDataCell className="text-center">
                                    {item.isIdentityVerified ? (
                                      <svg
                                        width="30"
                                        height="30"
                                        viewBox="0 0 30 30"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <circle cx="15" cy="15" r="15" fill="#2ACC96" />
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M12.6667 21.8667L6 15.2L7.86667 13.3333L12.6667 18.1333L22.8 8L24.6667 9.86667L12.6667 21.8667Z"
                                          fill="white"
                                        />
                                      </svg>
                                    ) : (
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'space-evenly',
                                        }}
                                      >
                                        <Verifie
                                          handleVerification={handleVerification}
                                          id={item.id}
                                        />
                                      </div>
                                    )}
                                  </CTableDataCell>
                                  <CTableDataCell style={{ width: '15%' }}>
                                    <div
                                      className="text-center"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <Link to={`/put/bricoleur/${item.id}`}>
                                        <CIcon
                                          icon={cilPen}
                                          title="Modifie"
                                          style={{
                                            color: '#2ACC96',
                                            border: '2px solid #2ACC96',
                                            borderRadius: '100%',
                                            margin: '9px',
                                            cursor: 'pointer',
                                          }}
                                          size="xl"
                                        />
                                      </Link>
                                      <span>
                                        <CIcon
                                          title="supprimer"
                                          icon={cilX}
                                          style={{
                                            color: 'red',
                                            border: '2px solid red',
                                            borderRadius: '100%',
                                            margin: '5px',
                                            cursor: 'pointer',
                                          }}
                                          size="xl"
                                          onClick={() => {
                                            setVisible(true)
                                            setIsDelete(true)
                                            setIsBlock(false)
                                            setBricoleurId(item.id)
                                          }}
                                        />
                                      </span>
                                      {item.isBlocked ? (
                                        <span
                                          onClick={() => {
                                            setIsDelete(false)
                                            setIsBlock(true)
                                            setVisible(true)
                                            setBricoleurId(item.id)
                                          }}
                                        >
                                          <img
                                            src={unblock}
                                            alt="block"
                                            title={'Debloquer'}
                                            style={{
                                              cursor: 'pointer',
                                              padding: '1px',
                                              margin: '9px',
                                            }}
                                          />
                                        </span>
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setIsDelete(false)
                                            setIsBlock(false)
                                            setVisible(true)
                                            setBricoleurId(item.id)
                                          }}
                                        >
                                          <img
                                            src={blOck}
                                            alt="block"
                                            title={'bloquer'}
                                            style={{
                                              cursor: 'pointer',
                                              padding: '1px',
                                              margin: '9px',
                                            }}
                                          />
                                        </span>
                                      )}
                                      <span>
                                        <CIcon
                                          title="supprimer"
                                          icon={cilLockLocked}
                                          style={{
                                            cursor: 'pointer',
                                            borderRadius: '100%',
                                            marginTop: '4px',
                                            marginLeft: '1px',
                                          }}
                                          size="xl"
                                          onClick={() => {
                                            history.push(`${item.id}/change`)
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </CTableDataCell>
                                </CTableRow>
                              ))}
                          </CTableBody>
                        </CTable>
                      </CCardBody>
                    ) : (
                      <CAlert
                        color="warning"
                        className="d-flex align-items-center w-100 mx-auto justify-content-center "
                      >
                        <CIcon
                          icon={cilWarning}
                          className="flex-shrink-0 me-2"
                          width={24}
                          height={24}
                        />
                        <div>Les données que vous voulez nexistent pas</div>
                      </CAlert>
                    )}
                  </CCard>
                </CCol>
              )}
            </Paginated>

            {/*  */}
          </CRow>
        </>
      )}
    </DefaultLayout>
  )
}

Bricoleurs.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default Bricoleurs
