import React, { useEffect, useState } from 'react'
import DefaultLayout from 'src/layout/DefaultLayout'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CHeader,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import { Link } from 'react-router-dom'
import CIcon from '@coreui/icons-react'
import { cilPen, cilPlus, cilX } from '@coreui/icons'
import Paginated from 'src/components/paginated/Paginated'
import 'toastr/build/toastr.css'
import NoCodes from '../nodata/NoCodes'
import axios from 'axios'
import { URL__API } from 'src/axios'
import Loading from 'src/components/Loading'

function Codes() {
  const [codes, setCodes] = useState([])
  const [visible, setVisible] = useState(false)
  const [codeId, setCodeId] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchCodes()
  }, [])
  const fetchCodes = async () => {
    const res = await axios.get(`${URL__API}/codepromos`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })

    if ([200, 201].includes(res.status)) {
      setLoading(false)
      console.log(res.data)
      setCodes(res.data)
    }
    if (res.data.length === 0) {
      return <NoCodes msg="de codes promos" />
    }
  }

  const handleAccept = async (id) => {
    try {
      await axios.delete(`${URL__API}/codepromos/${id}`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      setVisible(false)
      fetchCodes()
    } catch (err) {
      console.error(err)
    }
  }

  if (loading) {
    return (
      <DefaultLayout>
        <Loading />
      </DefaultLayout>
    )
  }

  return (
    <DefaultLayout>
      {codes.length === 0 ? (
        <NoCodes msg="de codes promos" />
      ) : (
        <>
          <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
              <CModalTitle>Suppression</CModalTitle>
            </CModalHeader>
            <CModalBody>Etes-vous sûr de supprimer cette code ?</CModalBody>
            <CModalFooter>
              <CButton color="secondary" onClick={() => setVisible(false)}>
                Non
              </CButton>
              <CButton color="primary" onClick={() => handleAccept(codeId)}>
                Oui
              </CButton>
            </CModalFooter>
          </CModal>

          <Paginated items={codes}>
            {(paginatedVariables) => (
              <CCol xs>
                <CCard className="mb-4">
                  <CHeader className="mr-4">
                    <CCardHeader>Variables</CCardHeader>
                    <Link
                      to="/addcode"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'white',
                        background: '#2ACC96',
                        border: '#2ACC96',
                        textDecoration: 'none',
                        borderRadius: '20px',
                        margin: '20px',
                        paddingRight: '25px',
                        paddingLeft: '25px',
                        paddingBottom: '16px',
                        paddingTop: '16px',
                      }}
                    >
                      <CIcon icon={cilPlus} size="lg" style={{ marginRight: '16px' }}></CIcon>
                      Ajouter un code
                    </Link>
                  </CHeader>
                  <CCardBody>
                    <br />
                    <CTable align="middle" className="mb-0 border" hover responsive>
                      <CTableHead color="light">
                        <CTableRow className="text-center">
                          <CTableHeaderCell className="text-center">Code</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Valeur</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">
                            Maximum d&apos;utilisations
                          </CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Type</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Actions</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>

                      <CTableBody>
                        {paginatedVariables &&
                          paginatedVariables.map((item, index) => (
                            <CTableRow
                              v-for="item in tableItems"
                              className="text-center"
                              key={index}
                            >
                              <CTableDataCell className="text-center">{item.code}</CTableDataCell>
                              <CTableDataCell className="text-center">{item.value}</CTableDataCell>
                              <CTableDataCell className="text-center">{item.max}</CTableDataCell>

                              {item?.type === 'P' && (
                                <CTableDataCell className="text-center">Pourcentage</CTableDataCell>
                              )}

                              {item?.type === 'M' && (
                                <CTableDataCell className="text-center">Mountant</CTableDataCell>
                              )}

                              <CTableDataCell className="text-center">
                                <Link to={`/code/${item.id}`}>
                                  <CIcon
                                    className="text-center"
                                    icon={cilPen}
                                    title="Modifie"
                                    style={{
                                      color: '#2ACC96',
                                      border: '2px solid #2ACC96',
                                      borderRadius: '100%',
                                      margin: '9px',
                                      cursor: 'pointer',
                                    }}
                                    size="xl"
                                  />
                                </Link>
                                <CIcon
                                  title="supprimer"
                                  icon={cilX}
                                  style={{
                                    cursor: 'pointer',
                                    color: 'red',
                                    border: '2px solid red',
                                    borderRadius: '100%',
                                    padding: '1px',
                                    margin: '9px',
                                  }}
                                  size="xl"
                                  onClick={() => {
                                    setVisible(true)
                                    setCodeId(item.id)
                                  }}
                                />
                              </CTableDataCell>
                            </CTableRow>
                          ))}
                      </CTableBody>
                    </CTable>
                  </CCardBody>
                </CCard>
              </CCol>
            )}
          </Paginated>
        </>
      )}
    </DefaultLayout>
  )
}

export default Codes
