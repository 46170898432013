import React, { useEffect, useState } from 'react'
import {
  CAlert,
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
} from '@coreui/react'
import axios from 'axios'
import { Formik } from 'formik'
import { URL__API } from 'src/axios'
import DefaultLayout from 'src/layout/DefaultLayout'
import { PropTypes } from 'prop-types'
import * as Yup from 'yup'
import { withRouter } from 'react-router-dom'
import './job.css'

function AddJobs({ history }) {
  const [fail, setFailValue] = useState(false)

  const ajouter = async ({ job, file }) => {
    const formData = new FormData()
    formData.append('job', job)
    formData.append('image', file)
    formData.append('type', 'job')
    try {
      const res = await axios.post(`${URL__API}/jobs/new-job`, formData, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      history.push('/métier')
    } catch (error) {
      if (error) {
        setFailValue(true)
      }
      console.error(error)
    }
  }

  const validationSchema = Yup.object().shape({
    job: Yup.string().required('Veuillez renseigner ce champ'),
    file: Yup.object().nullable(),
  })

  return (
    <DefaultLayout>
      <div className="job">
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            job: '',
            file: null,
          }}
          onSubmit={ajouter}
        >
          {({ values, touched, errors, handleChange, handleSubmit, setFieldValue }) => (
            <CForm className="job_new" onSubmit={handleSubmit}>
              <CRow className="job_add_field">
                <CCol xs>
                  <CFormLabel>Nom</CFormLabel>
                  <CFormInput
                    placeholder="job"
                    type="text"
                    name="job"
                    value={values.job}
                    onChange={handleChange}
                  />
                  {errors.job && touched.job && <p style={{ color: 'red' }}>{errors.job}</p>}
                </CCol>
              </CRow>
              <CRow className="job_add_field">
                <CCol xs>
                  <CFormLabel>Icon</CFormLabel>
                  <CFormInput
                    placeholder="image"
                    type="file"
                    name="file"
                    onChange={(e) => setFieldValue('file', e.currentTarget.files[0])}
                  />
                  {errors.file && touched.file && <p style={{ color: 'red' }}>{errors.file}</p>}
                </CCol>
              </CRow>

              <CRow className="job_add_field">
                <CCol xs>
                  <CButton type="submit" className="w-100 py-2 job_btn">
                    Ajouter
                  </CButton>
                </CCol>
              </CRow>
              {fail && (
                <CAlert
                  style={{
                    marginTop: '10px',
                  }}
                  color="danger"
                >
                  Ce metrier existe déjà
                </CAlert>
              )}
            </CForm>
          )}
        </Formik>
      </div>
    </DefaultLayout>
  )
}

AddJobs.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default withRouter(AddJobs)
