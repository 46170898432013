import {
  CAvatar,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardLink,
  CCardText,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
} from '@coreui/react'
import axios from 'axios'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { URL__API } from 'src/axios'
import DefaultLayout from 'src/layout/DefaultLayout'
import avatar from '../../../assets/images/avatars/Avatars.png'
import { PropTypes } from 'prop-types'
import * as Yup from 'yup'
import { withRouter } from 'react-router-dom'

import File from '../../../assets/file.png'
import './compte.css'
import Loading from 'src/components/Loading'

function Compte({ history }) {
  const [admin, setAdmin] = useState(null)
  const [display, setDisaplay] = useState(false)
  const [formData, setFormaData] = useState(new FormData())
  const [loading, setLoading] = useState(true)

  const update = async ({ firstName, lastname, email, phone }) => {
    try {
      formData.append('firstName', firstName)
      formData.append('lastname', lastname)
      formData.append('email', email)
      formData.append('phone', phone)
      formData.append('type', 'avatar')
      const res = await axios.put(
        `${URL__API}/user/update/${localStorage.getItem('user_id')}`,
        formData,
        {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      )
      if (res.status === 200) {
        history.push('/compte')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchMe = async () => {
    const res = await axios.get(`${URL__API}/user/profile/${localStorage.getItem('user_id')}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    if ([200, 201].includes(res.status)) {
      setLoading(false)
      setAdmin(res.data)
    }
  }

  useEffect(() => {
    fetchMe()
  }, [])

  const handleChangeFile = async (e) => {
    formData.append('type', 'avatar')
    formData.append('image', e.target.files[0])

    const res = await axios.post(`${URL__API}/files/avatar`, formData, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    if ([200, 201].includes(res.status)) {
      setLoading(true)
      fetchMe()
      setLoading(false)
    }
  }
  if (loading) {
    return (
      <DefaultLayout>
        <Loading />
      </DefaultLayout>
    )
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Veuillez renseigner ce champ'),
    lastname: Yup.string().required('Veuillez renseigner ce champ'),
    password: Yup.string().required('Veuillez renseigner ce champ'),
    email: Yup.string().email("Format d'email invalide").required('Veuillez renseigner ce champ'),
    phone: Yup.string().required('Veuillez renseigner ce champ'),
  })

  return (
    <DefaultLayout>
      <CCard className="card_container">
        <CCardHeader className="py-3">
          <div className="header_container">
            <div className="compte_header">
              <CAvatar
                className="mt-2 mx-3"
                size="xl"
                src={admin?.avatar ? admin?.avatar : avatar}
              />
              <label className="compte_label">
                <img src={File} alt="ellipse" />
                <input type="file" hidden name="file" onChange={handleChangeFile} />
              </label>
            </div>
            <div>
              <h3 className="compte_admin text-center mt-4">
                {admin?.firstName + ' ' + admin?.lastname}
              </h3>
              <h3 style={{ color: 'blue' }} className="compte_admin text-center mb-0">
                {admin?.role?.name}
              </h3>
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <div style={{ flex: 1 }}>
            {admin && (
              <div>
                <Formik validationSchema={validationSchema} initialValues={admin} onSubmit={update}>
                  {({ values, touched, errors, handleChange, handleSubmit }) => (
                    <CForm className="compte_form" onSubmit={handleSubmit}>
                      <CRow className=" compte_form_input">
                        <CCol xs className="compte_form_col">
                          <CFormLabel>Nom</CFormLabel>
                          <CFormInput
                            placeholder="Nom "
                            type="text"
                            name="firstName"
                            value={values?.firstName}
                            onChange={handleChange}
                            onFocus={() => setDisaplay(true)}
                          />
                          {errors.firstName && touched.firstName && (
                            <p style={{ color: 'red' }}>{errors.firstName}</p>
                          )}
                        </CCol>
                        <CCol xs className="compte_form_col">
                          <CFormLabel>Prénom</CFormLabel>
                          <CFormInput
                            placeholder="Nom et Prenom"
                            type="text"
                            name="lastname"
                            value={values?.lastname}
                            onChange={handleChange}
                            onFocus={() => setDisaplay(true)}
                          />
                          {errors.lastname && touched.lastname && (
                            <p style={{ color: 'red' }}>{errors.lastname}</p>
                          )}
                        </CCol>
                      </CRow>
                      <CRow className="compte_form_input">
                        <CCol xs className="compte_form_col">
                          <CFormLabel>Email</CFormLabel>
                          <CFormInput
                            placeholder="Email"
                            type="email"
                            name="email"
                            value={values?.email}
                            onChange={handleChange}
                          />
                          {errors.email && touched.email && (
                            <p style={{ color: 'red' }}>{errors.email}</p>
                          )}
                        </CCol>
                        <CCol xs className="compte_form_col">
                          <CFormLabel>Telephone</CFormLabel>
                          <CFormInput
                            placeholder="Telephone"
                            type="text"
                            name="phone"
                            value={values?.phone}
                            onChange={handleChange}
                          />
                          {errors.phone && touched.phone && (
                            <p style={{ color: 'red' }}>{errors.phone}</p>
                          )}
                        </CCol>
                      </CRow>

                      <div className="compte_btns">
                        <CRow className="compte_btn_row">
                          <CCol xs>
                            <CButton
                              type="submit"
                              disabled={!display}
                              style={{
                                color: 'white',
                                background: `${display ? '#FC376B' : 'grey'}`,
                                border: '#2ACC96',
                                textDecoration: 'none',
                              }}
                              className="px-5"
                              onClick={() => setDisaplay(false)}
                            >
                              Annuler
                            </CButton>
                          </CCol>
                        </CRow>
                        <CRow className="compte_btn_row">
                          <CCol xs>
                            <CButton
                              disabled={!display}
                              type="submit"
                              onClick={() =>
                                update({
                                  firstName: values.firstName,
                                  lastname: values.lastname,
                                  email: values.email,
                                  phone: values.phone,
                                })
                              }
                              style={{
                                color: 'white',
                                background: `${display ? '#2ACC96' : 'grey'}`,
                                border: '#2ACC96',
                                textDecoration: 'none',
                              }}
                              className="px-5 "
                            >
                              Register
                            </CButton>
                          </CCol>
                        </CRow>
                        <CRow className="mt-3">
                          <CCol xs>
                            <CCardText>
                              <CCardLink href="/change" style={{ color: '#8E8E8E' }}>
                                Changer le mot de passe
                              </CCardLink>
                            </CCardText>
                          </CCol>
                        </CRow>
                      </div>
                    </CForm>
                  )}
                </Formik>
              </div>
            )}
          </div>
        </CCardBody>
      </CCard>
    </DefaultLayout>
  )
}

Compte.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default withRouter(Compte)
