import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PrivateComponent from './private/PrivateComponent'
import './scss/style.scss'
import Dashboard from './views/dashboard/Dashboard'
import AddBricoleurs from './views/pages/bricoleurs/Add'
import Bricoleur from './views/pages/bricoleurs/Bricoleur'
import BricoleurInfo from './views/pages/bricoleurs/BricoleurInfo'
import Bricoleurs from './views/pages/bricoleurs/Bricoleurs'
import UpdateBricoleurs from './views/pages/bricoleurs/Update'
import ChangePassword from './views/pages/change/ChangePassword'
import ChangePasswordAdmin from './views/pages/change/ChangePasswordAdmin'
import Cities from './views/pages/city/Cities'
import UpdateCity from './views/pages/city/UpdateCity'
import AddCode from './views/pages/codes/AddCode'
import Codes from './views/pages/codes/Codes'
import UpdateCode from './views/pages/codes/UpdateCode'
import Compte from './views/pages/compte/Compte'
import Demande from './views/pages/demandes/Demande'
import UpdateDemande from './views/pages/demandes/UpdateDemande'
import AddJobs from './views/pages/jobs/AddJobs'
import AddNewJobCat from './views/pages/jobs/AddNewJobCat'
import JobCats from './views/pages/jobs/JobCats'
import Jobs from './views/pages/jobs/Jobs'
import UpdateJob from './views/pages/jobs/UpdateJob'
import UpdateJobCats from './views/pages/jobs/UpdateJobCats'
import Message from './views/pages/messages/Message'
import MessageInfo from './views/pages/messages/MessageInfo'
import Signal from './views/pages/signal/Signal'
import SignalInfo from './views/pages/signal/SignalInfo'
import AddUsers from './views/pages/users/AddUsers'
import UpdateUsers from './views/pages/users/UpdateUser'
import Users from './views/pages/users/Users'
import AddVariable from './views/pages/variables/AddVariable'
import UpdateVariable from './views/pages/variables/UpdateVariable'
import Variables from './views/pages/variables/Variables'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
// const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <Router>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <PrivateComponent exact path="/" name="Dashboard" component={Dashboard} />
            <PrivateComponent
              exact
              path="/change"
              name="ChangePassword"
              component={ChangePassword}
            />
            <PrivateComponent
              exact
              path="/:id/change"
              name="ChangePassword"
              component={ChangePasswordAdmin}
            />
            <PrivateComponent exact path="/bricoleurs" name="Bricoleurs" component={Bricoleurs} />
            <PrivateComponent
              exact
              path="/addbricoleurs"
              name="AddBricoleurs"
              component={AddBricoleurs}
            />

            <PrivateComponent
              exact
              path="/addjobcats"
              name="AddNewJobCat"
              component={AddNewJobCat}
            />

            <PrivateComponent exact path="/metier/:id" name="UpdateJob" component={UpdateJob} />
            <PrivateComponent
              exact
              path="/demande/:id"
              name="UpdateDemande"
              component={UpdateDemande}
            />

            <PrivateComponent exact path="/bricoleur/:id" name="Bricoleur" component={Bricoleur} />
            <PrivateComponent
              exact
              path="/bricoleur/:id/info"
              name="Bricoleur"
              component={BricoleurInfo}
            />

            <PrivateComponent exact path="/variables" name="Variables" component={Variables} />
            <PrivateComponent exact path="/codes" name="Codes" component={Codes} />
            <PrivateComponent exact path="/addcode" name="Ajouter code" component={AddCode} />
            <PrivateComponent
              exact
              path="/code/:id"
              name="Modifier une code"
              component={UpdateCode}
            />
            <PrivateComponent
              exact
              path="/addvariable"
              name="AddVariables"
              component={AddVariable}
            />

            <PrivateComponent
              exact
              path="/variable/:id"
              name="UpdateVariable"
              component={UpdateVariable}
            />

            <PrivateComponent exact path="/signalements" name="Signal" component={Signal} />
            <PrivateComponent exact path="/:id/signal" name="SignalInfo" component={SignalInfo} />

            <PrivateComponent exact path="/cities" name="Villes" component={Cities} />
            <PrivateComponent
              exact
              path="/put/city/:id"
              name="ModifierVilles"
              component={UpdateCity}
            />

            <PrivateComponent exact path="/messages" name="Messages" component={Message} />
            <PrivateComponent
              exact
              path="/:id/chat/:senderId/:receiverId"
              name="ChatInfo"
              component={MessageInfo}
            />

            <PrivateComponent
              exact
              path="/put/bricoleur/:id"
              name="UpdateBricoleurs"
              component={UpdateBricoleurs}
            />
            <PrivateComponent exact path="/user/:id" name="UpdateUser" component={UpdateUsers} />
            <PrivateComponent
              exact
              path="/categorie/:id"
              name="UpdateUser"
              component={UpdateJobCats}
            />
            <PrivateComponent exact path="/job/:id" name="JobCats" component={JobCats} />
            <PrivateComponent exact path="/users" name="Users" component={Users} />
            <PrivateComponent exact path="/addusers" name="AddUsers" component={AddUsers} />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <PrivateComponent exact path="/compte" name="Compte" component={Compte} />
            <PrivateComponent exact path="/métier" name="Métier" component={Jobs} />
            <PrivateComponent exact path="/addjobs" name="AddJobs" component={AddJobs} />
            <PrivateComponent exact path="/demandes" name="Demande" component={Demande} />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
          </Switch>
        </React.Suspense>
      </Router>
    )
  }
}

export default App
