import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import axios from 'axios'
import { URL__API } from 'src/axios'
import DefaultLayout from 'src/layout/DefaultLayout'
import avatar from '../../../assets/images/avatars/Avatars.png'
import {
  CAvatar,
  CButton,
  CCard,
  CCardBody,
  CCardText,
  CCardTitle,
  CCol,
  CFormInput,
  CFormSelect,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import Loading from 'src/components/Loading'
import { CChartLine } from '@coreui/react-chartjs'
import { format } from 'date-fns'
import Paginated from 'src/components/paginated/Paginated'
import CIcon from '@coreui/icons-react'
import { cilPen } from '@coreui/icons'

function BricoleurInfo({ history, match }) {
  const date = new Date()
  const [bricoleur, setBricoleur] = useState(null)
  const [value, setValue] = useState(null)
  const [transactions, setTransactions] = useState([])
  const [ended, setEnded] = useState('')
  const [canceled, setCanceled] = useState('')
  const [solde, setSolde] = useState(bricoleur?.solde)
  const [month, setMonth] = useState(date.getMonth() + 1)
  const [year, setYear] = useState(date.getFullYear())
  const [show, setShow] = useState(false)

  const fetchBricoleur = async () => {
    const res = await axios.get(`${URL__API}/user/profile/${match.params.id}`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    if (res.status !== 200 || res.status === 404) {
    }

    setSolde(res.data?.solde)
    setBricoleur(res.data)
  }

  const fetchTransactions = async () => {
    const res = await axios.get(`${URL__API}/transactions/${match.params.id}`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })

    if (res.status !== 200 || res.status === 404) {
    }

    setTransactions(res.data)
  }

  useEffect(() => {
    fetchBricoleur()
  }, [show])

  useEffect(() => {
    fetchTransactions()

    const fecthEnded = async () => {
      const res = await axios.get(`${URL__API}/user/${match.params.id}/ended-offers`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      })

      setEnded(res.data.meta.totalItems)
    }
    fecthEnded()

    const fetchCanceled = async () => {
      const res = await axios.get(`${URL__API}/user/${match.params.id}/canceled-offers`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      })

      setCanceled(res.data.meta.totalItems)
    }
    fetchCanceled()
  }, [])

  useEffect(() => {
    handleFilter()
  }, [month, year])

  const handleSolde = async () => {
    try {
      await axios.patch(
        `${URL__API}/user/${match.params.id}/solde`,
        { solde: +solde },
        {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      )
      setShow(false)
      fetchBricoleur()
    } catch (e) {
      console.error(e)
    }
  }

  const handleFilter = async (val) => {
    try {
      if (true) {
        const res = await axios.get(
          `${URL__API}/transactions/${match.params.id}/filter?month=${month}&year=${year}`,
          {
            headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
          },
        )

        setValue(res.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  if (bricoleur === null) {
    return (
      <DefaultLayout>
        <Loading />
      </DefaultLayout>
    )
  }

  return (
    <DefaultLayout>
      {bricoleur === null ? (
        <Loading />
      ) : (
        <CCard className="">
          <div className="bricoleur_info">
            <div className="bricoleur_infro_header ">
              <CAvatar
                className="mx-3"
                src={bricoleur?.avatar ? bricoleur?.avatar : avatar}
                size="xl"
              />
              <div>
                <h4 style={{ marginBottom: '5' }}>
                  {bricoleur?.firstName + ' ' + bricoleur?.lastname}
                </h4>
                <p style={{ color: '#2ACC96', fontSize: '20px', fontWeight: 'normal' }}>
                  {bricoleur?.job?.name}
                </p>
              </div>
            </div>
            <div
              className="bricoleur_infro_combo "
              // style={{
              //   display: 'flex',
              //   justifyContent: 'space-evenly',
              //   width: '45%',
              // }}
            >
              <CCol xs="2" style={{ width: '40%' }}>
                <p>Le mois</p>
                <CFormSelect
                  defaultValue={month}
                  onChange={(e) => setMonth(e.target.value)}
                  options={[
                    { value: 1, label: 'Janvier' },
                    { value: 2, label: 'Février' },
                    { value: 3, label: 'Mars' },
                    { value: 4, label: 'Avril' },
                    { value: 5, label: 'Mai' },
                    { value: 6, label: 'Juin' },
                    { value: 7, label: 'Juillet' },
                    { value: 8, label: 'Aout' },
                    { value: 9, label: 'Septembre' },
                    { value: 10, label: 'Octobre' },
                    { value: 11, label: 'Novembre' },
                    { value: 12, label: 'Décembre' },
                  ]}
                />
              </CCol>
              <CCol xs="2" style={{ width: '40%' }}>
                <p>Annee</p>
                <CFormSelect
                  defaultValue={year}
                  onChange={(e) => setYear(e.target.value)}
                  options={[
                    { value: date.getFullYear(), label: `${date.getFullYear()}` },
                    { value: date.getFullYear() - 1, label: `${date.getFullYear() - 1}` },
                  ]}
                />
              </CCol>
            </div>
          </div>
          <vr />
          <div className="mx-5">
            <h2>Statistiques</h2>
            <div className="bricoleur_info_chart">
              <div className="d-flex w-100 h-100 mr-3" style={{ flexDirection: 'column' }}>
                <div className=" bricoleur_statistic">
                  <CCard className="m-3 " style={{ width: '40%', height: '35%' }}>
                    <CCardBody className="">
                      <div className="d-flex justify-content-between">
                        <CCardTitle className="mb-5">Solde courant</CCardTitle>
                        <CIcon
                          icon={cilPen}
                          title="Modifie"
                          style={{
                            color: '#2ACC96',
                            border: '2px solid #2ACC96',
                            borderRadius: '100%',
                            margin: '9px',
                            cursor: 'pointer',
                          }}
                          size="xl"
                          onClick={() => setShow(true)}
                        />
                      </div>
                      {show ? (
                        <div className="d-flex">
                          <CFormInput
                            onChange={(e) => setSolde(e.target.value)}
                            type="number"
                            placeholder="Solde"
                            name="email"
                            className="w-75"
                            aria-label="email"
                            aria-describedby="basic-addon1"
                            value={solde}
                          />
                          <CButton
                            color="primary"
                            className="py-1 mx-2"
                            type="submit"
                            onClick={handleSolde}
                          >
                            Valider
                          </CButton>
                        </div>
                      ) : (
                        <CCardText style={{ fontSize: '1.75rem' }}>{bricoleur?.solde} DH</CCardText>
                      )}
                    </CCardBody>
                  </CCard>
                  <CCard className="m-3" style={{ width: '40%', height: '35%' }}>
                    <CCardBody>
                      <CCardTitle className="mb-5">Missions terminées</CCardTitle>
                      <CCardText style={{ fontSize: '1.75rem' }}>{ended}</CCardText>
                    </CCardBody>
                  </CCard>
                </div>
                <div className="bricoleur_statistic">
                  <CCard className="m-3" style={{ width: '40%', height: '35%' }}>
                    <CCardBody>
                      <CCardTitle className="mb-5">Revenu</CCardTitle>
                      <CCardText style={{ fontSize: '1.75rem' }}>{value?.totalIncomes}</CCardText>
                    </CCardBody>
                  </CCard>
                  <CCard className="m-3" style={{ width: '40%', height: '35%' }}>
                    <CCardBody>
                      <CCardTitle className="mb-5">Annulations</CCardTitle>
                      <CCardText style={{ fontSize: '1.75rem' }}>{canceled}</CCardText>
                    </CCardBody>
                  </CCard>
                </div>
              </div>

              <CChartLine
                style={{ width: '60%' }}
                className="bricoleur_chart"
                type="line"
                data={{
                  labels: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
                  datasets: [
                    {
                      label: 'Transactions',
                      backgroundColor: '#29129D',
                      borderColor: '#29129D',
                      pointBackgroundColor: '#29129D',
                      pointBorderColor: '#fff',
                      data: value?.transactions.map((transaction) => transaction.montant),
                    },
                  ],
                  fill: true,
                }}
              />
            </div>
          </div>
          <div className="mx-5 my-3">
            <h2 className="my-3">Historique des transactions</h2>
            <div className="">
              <Paginated items={transactions}>
                {(transaction) => (
                  <CTable bordered color="light">
                    <CTableHead>
                      <CTableRow color="light">
                        <CTableHeaderCell scope="col">Nom</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Type de transaction</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Montant</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Date</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {transaction.map((transaction) => (
                        <CTableRow key={transaction.id}>
                          <CTableHeaderCell scope="row" className="d-flex align-items-center">
                            <div style={{ width: '3rem', height: '3rem', textAlign: 'center' }}>
                              <img
                                alt="avatar"
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  borderRadius: '50%',
                                }}
                                src={
                                  transaction?.client?.avatar ? transaction?.client?.avatar : avatar
                                }
                              />
                            </div>
                            <CTableDataCell className="mx-3">
                              {transaction?.client?.firstName + ' ' + transaction?.client?.lastname}
                            </CTableDataCell>
                          </CTableHeaderCell>
                          <CTableDataCell>{transaction.type}</CTableDataCell>
                          <CTableDataCell>{transaction.montant}</CTableDataCell>
                          <CTableDataCell>
                            {' '}
                            {format(new Date(transaction.createdAt), 'dd/MM/yyyy')}
                          </CTableDataCell>
                        </CTableRow>
                      ))}
                    </CTableBody>
                  </CTable>
                )}
              </Paginated>
            </div>
          </div>
        </CCard>
      )}
    </DefaultLayout>
  )
}

BricoleurInfo.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
}

export default BricoleurInfo
