import React, { useEffect, useState } from 'react'
import { CFormSelect, CPagination, CPaginationItem } from '@coreui/react'
import PropTypes from 'prop-types'
import './paginated.css'

const Paginated = ({ items, children }) => {
  const [paginatedData, setPaginatedData] = useState([])
  const [paginated, setPaginated] = useState([])
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(10)

  useEffect(() => {
    setPaginatedData([...(items?.length > 0 ? items : items)].splice(page * perPage, perPage))
  }, [page, items, perPage])

  useEffect(() => {
    setPage(0)
    setPaginated(Array(Math.ceil(items?.length / perPage)).fill())
  }, [items, perPage])

  return (
    <>
      {children && children(paginatedData)}
      {items.length && (
        <div className="paginated">
          <CPagination aria-label="Page navigation example" className="offer">
            <CPaginationItem
              aria-label="Previous"
              style={{ cursor: 'pointer' }}
              onClick={() => (page === 0 ? null : setPage((p) => p - 1))}
              disabled={page === 0}
            >
              &laquo;
            </CPaginationItem>
            <CPaginationItem style={{ cursor: 'pointer' }} className="paginated__item text-center">
              {page + 1} / {paginated.length}
            </CPaginationItem>

            <CPaginationItem
              aria-label="Next"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                page === Math.ceil(items.length / perPage - 1) ? null : setPage((p) => p + 1)
              }
              disabled={page === Math.ceil(items.length / perPage - 1)}
            >
              &raquo;
            </CPaginationItem>
          </CPagination>
          <div className="paginated_page">
            <label>Per page :</label>
            <CFormSelect
              style={{ width: '100px', height: 'fit-content' }}
              onChange={(v) => setPerPage(parseInt(v.currentTarget.value))}
              value={perPage}
              options={[
                { value: 5, label: '5' },
                { value: 10, label: '10' },
                { value: 20, label: '20' },
                { value: 50, label: '50' },
                { value: 100, label: '100' },
              ]}
            />
          </div>
        </div>
      )}
    </>
  )
}

Paginated.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  children: PropTypes.func.isRequired,
}

export default Paginated
