import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCol, CForm, CFormInput, CFormLabel, CRow } from '@coreui/react'
import axios from 'axios'
import { Formik } from 'formik'
import { URL__API } from 'src/axios'
import DefaultLayout from 'src/layout/DefaultLayout'
import { PropTypes } from 'prop-types'
import * as Yup from 'yup'
import Loading from 'src/components/Loading'
import './variable.css'

function UpdateVariable({ history, match }) {
  const [variable, setVariable] = useState(null)
  const [fail, setFailValue] = useState(false)

  const update = async ({ label, value }) => {
    try {
      const res = await axios.patch(
        `${URL__API}/variables/${match.params.id}`,
        { label, value },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )

      if (res.status === 200) {
        history.push('/variables')
      }
    } catch (error) {
      if (error) {
        setFailValue(true)
      }
      console.error(error)
    }
  }
  useEffect(() => {
    const fetchOneJob = async () => {
      const res = await axios.get(`${URL__API}/variables/${match.params.id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })

      setVariable(res.data)
    }
    fetchOneJob()
  }, [match.params.id])

  const validationSchema = Yup.object().shape({
    label: Yup.string().required('Veuillez renseigner ce champ'),
    value: Yup.string().required('Veuillez renseigner ce champ'),
  })

  return (
    <DefaultLayout>
      {fail || !variable ? (
        <Loading />
      ) : (
        <CCard className="variable_card">
          <Formik validationSchema={validationSchema} initialValues={variable} onSubmit={update}>
            {({ values, touched, errors, handleChange, handleSubmit, setFieldValue }) => (
              <CForm className="variable" onSubmit={handleSubmit}>
                <CRow className="variable_field">
                  <CCol xs>
                    <CFormLabel>Label</CFormLabel>
                    <CFormInput
                      placeholder="label"
                      type="text"
                      name="label"
                      value={values.label}
                      onChange={handleChange}
                    />
                    {errors.label && touched.label && (
                      <p style={{ color: 'red' }}>{errors.label}</p>
                    )}
                  </CCol>
                </CRow>

                <CRow className="variable_field">
                  <CCol xs>
                    <CFormLabel>Valeur</CFormLabel>
                    <CFormInput
                      placeholder="valeur"
                      type="text"
                      name="value"
                      value={values.value}
                      onChange={handleChange}
                    />
                    {errors.value && touched.value && (
                      <p style={{ color: 'red' }}>{errors.value}</p>
                    )}
                  </CCol>
                </CRow>

                <CRow className="variable_field">
                  <CCol xs>
                    <CButton
                      type="submit"
                      style={{
                        color: 'white',
                        background: '#2ACC96',
                        border: '#2ACC96',
                        textDecoration: 'none',
                      }}
                      className="w-100 py-2"
                    >
                      Modifier
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>
            )}
          </Formik>
        </CCard>
      )}
    </DefaultLayout>
  )
}

UpdateVariable.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
}

export default UpdateVariable
