import React from 'react'
import { useHistory } from 'react-router-dom'

function Variable() {
  let { location } = useHistory()

  return (
    <svg
      width="30"
      height="35"
      style={{ marginRight: '15px', marginLeft: '5px' }}
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.875 26.25H3.75V30H16.875V35.625L24.375 28.125L16.875 20.625V26.25ZM28.125 24.375V18.75H41.25V15H28.125V9.375L20.625 16.875L28.125 24.375Z"
        fill={`${location.pathname === '/variables' ? '#2ACC96' : 'white'}`}
      />
    </svg>
  )
}

export default Variable
