import React from 'react'
import { useHistory } from 'react-router-dom'

function Signals() {
  let { location } = useHistory()

  return (
    <div
      style={{
        color: `${location.pathname === '/signalements' ? '#2ACC96' : 'white'}`,
      }}
    >
      Signalements
    </div>
  )
}

export default Signals
