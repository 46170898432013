import React from 'react'
import Cities from './components/names/Cities'
import Compte from './views/pages/compte/Compte'
import Demande from './views/pages/demandes/Demande'
import AddJobs from './views/pages/jobs/AddJobs'
import AddNewJobCat from './views/pages/jobs/AddNewJobCat'
import Jobs from './views/pages/jobs/Jobs'
import Message from './views/pages/messages/Message'
import Signal from './views/pages/signal/Signal'
import UpdateUsers from './views/pages/users/UpdateUser'
// import Bricoleurs from './views/pages/bricoleurs/Bricoleurs'

const Bricoleurs = React.lazy(() => import('./views/pages/bricoleurs/Bricoleurs'))
const JobCats = React.lazy(() => import('./views/pages/jobs/JobCats'))
const Variables = React.lazy(() => import('./views/pages/variables/Variables'))
const Codes = React.lazy(() => import('./views/pages/codes/Codes'))
const MessageInfo = React.lazy(() => import('./views/pages/messages/MessageInfo'))
const UpdateBricoleurs = React.lazy(() => import('./views/pages/bricoleurs/Update'))
const UpdateCode = React.lazy(() => import('./views/pages/codes/UpdateCode'))
const AddBricoleurs = React.lazy(() => import('./views/pages/bricoleurs/Add'))
const AddCodes = React.lazy(() => import('./views/pages/codes/AddCode'))
const ChangePassword = React.lazy(() => import('./views/pages/change/ChangePassword'))
const Users = React.lazy(() => import('./views/pages/users/Users'))
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Colors = React.lazy(() => import('./views/theme/colors/Colors'))
const Typography = React.lazy(() => import('./views/theme/typography/Typography'))

// Base
const Accordion = React.lazy(() => import('./views/base/accordion/Accordion'))
const Breadcrumbs = React.lazy(() => import('./views/base/breadcrumbs/Breadcrumbs'))
const Cards = React.lazy(() => import('./views/base/cards/Cards'))
const Carousels = React.lazy(() => import('./views/base/carousels/Carousels'))
const Collapses = React.lazy(() => import('./views/base/collapses/Collapses'))
const ListGroups = React.lazy(() => import('./views/base/list-groups/ListGroups'))
const Navs = React.lazy(() => import('./views/base/navs/Navs'))
const Paginations = React.lazy(() => import('./views/base/paginations/Paginations'))
const Placeholders = React.lazy(() => import('./views/base/placeholders/Placeholders'))
const Popovers = React.lazy(() => import('./views/base/popovers/Popovers'))
const Progress = React.lazy(() => import('./views/base/progress/Progress'))
const Spinners = React.lazy(() => import('./views/base/spinners/Spinners'))
const Tables = React.lazy(() => import('./views/base/tables/Tables'))
const Tooltips = React.lazy(() => import('./views/base/tooltips/Tooltips'))

// Buttons
const Buttons = React.lazy(() => import('./views/buttons/buttons/Buttons'))
const ButtonGroups = React.lazy(() => import('./views/buttons/button-groups/ButtonGroups'))
const Dropdowns = React.lazy(() => import('./views/buttons/dropdowns/Dropdowns'))

//Forms
const ChecksRadios = React.lazy(() => import('./views/forms/checks-radios/ChecksRadios'))
const FloatingLabels = React.lazy(() => import('./views/forms/floating-labels/FloatingLabels'))
const FormControl = React.lazy(() => import('./views/forms/form-control/FormControl'))
const InputGroup = React.lazy(() => import('./views/forms/input-group/InputGroup'))
const Layout = React.lazy(() => import('./views/forms/layout/Layout'))
const Range = React.lazy(() => import('./views/forms/range/Range'))
const Select = React.lazy(() => import('./views/forms/select/Select'))
const Validation = React.lazy(() => import('./views/forms/validation/Validation'))

const Charts = React.lazy(() => import('./views/charts/Charts'))

// Icons
const CoreUIIcons = React.lazy(() => import('./views/icons/coreui-icons/CoreUIIcons'))
const Flags = React.lazy(() => import('./views/icons/flags/Flags'))
const Brands = React.lazy(() => import('./views/icons/brands/Brands'))

// Notifications
const Alerts = React.lazy(() => import('./views/notifications/alerts/Alerts'))
const Badges = React.lazy(() => import('./views/notifications/badges/Badges'))
const Modals = React.lazy(() => import('./views/notifications/modals/Modals'))
const Toasts = React.lazy(() => import('./views/notifications/toasts/Toasts'))

const Widgets = React.lazy(() => import('./views/widgets/Widgets'))

const routes = [
  { path: '/', exact: true, name: 'Accueil' },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/bricoleurs', name: 'Bricoleurs', component: Bricoleurs },
  { path: '/addbricoleurs', exact: true, name: 'Ajouter bricoleur', component: AddBricoleurs },
  { path: '/addcode', exact: true, name: 'Ajouter code', component: AddCodes },
  { path: '/put/bricoleur/:id', name: 'Modifier bricoleur', component: UpdateBricoleurs },
  { path: '/user/:id', name: 'Modifier utilisateur', component: UpdateUsers },
  { path: '/code/:id', name: 'Modifier une code', component: UpdateCode },
  { path: '/job/:id', name: 'Métier', component: JobCats },
  { path: '/signalements', name: 'Signalements', component: Signal },
  { path: '/users', name: 'Utilisateurs', component: Users },
  { path: '/cities', name: 'Villes', component: Cities },
  { path: '/:id/chat/:senderId/:receiverId', name: 'Messages', component: MessageInfo },
  { path: '/messages', name: 'Messages', component: Message },
  { path: '/métier', name: 'Métiers', component: Jobs },
  { path: '/demandes', name: 'Demandes', component: Demande },
  { path: '/variables', name: 'Variables', component: Variables },
  { path: '/addjobs', name: 'Nouveau métier', component: AddJobs },
  { path: '/job/:id', exact: true, name: 'Sous-catégorie', component: JobCats },
  { path: '/addjobcats', name: 'Nouveau sous-catégorie', component: AddNewJobCat },
  { path: '/codes', name: 'Codes', component: Codes },
  { path: '/change', name: 'Changer le mot de passe', component: ChangePassword },
  { path: '/compte', name: 'Compte', component: Compte },
  { path: '/addusers', name: 'Ajouter utilisateur', component: Users },
  { path: '/theme', name: 'Theme', component: Colors, exact: true },
  { path: '/theme/colors', name: 'Colors', component: Colors },
  { path: '/theme/typography', name: 'Typography', component: Typography },
  { path: '/base', name: 'Base', component: Cards, exact: true },
  { path: '/base/accordion', name: 'Accordion', component: Accordion },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
  { path: '/base/carousels', name: 'Carousel', component: Carousels },
  { path: '/base/collapses', name: 'Collapse', component: Collapses },
  { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
  { path: '/base/navs', name: 'Navs', component: Navs },
  { path: '/base/paginations', name: 'Paginations', component: Paginations },
  { path: '/base/placeholders', name: 'Placeholders', component: Placeholders },
  { path: '/base/popovers', name: 'Popovers', component: Popovers },
  { path: '/base/progress', name: 'Progress', component: Progress },
  { path: '/base/spinners', name: 'Spinners', component: Spinners },
  { path: '/base/tables', name: 'Tables', component: Tables },
  { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
  { path: '/buttons', name: 'Buttons', component: Buttons, exact: true },
  { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
  { path: '/buttons/dropdowns', name: 'Dropdowns', component: Dropdowns },
  { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
  { path: '/charts', name: 'Charts', component: Charts },
  { path: '/forms', name: 'Forms', component: FormControl, exact: true },
  { path: '/forms/form-control', name: 'Form Control', component: FormControl },
  { path: '/forms/select', name: 'Select', component: Select },
  { path: '/forms/checks-radios', name: 'Checks & Radios', component: ChecksRadios },
  { path: '/forms/range', name: 'Range', component: Range },
  { path: '/forms/input-group', name: 'Input Group', component: InputGroup },
  { path: '/forms/floating-labels', name: 'Floating Labels', component: FloatingLabels },
  { path: '/forms/layout', name: 'Layout', component: Layout },
  { path: '/forms/validation', name: 'Validation', component: Validation },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', component: Flags },
  { path: '/icons/brands', name: 'Brands', component: Brands },
  { path: '/notifications', name: 'Notifications', component: Alerts, exact: true },
  { path: '/notifications/alerts', name: 'Alerts', component: Alerts },
  { path: '/notifications/badges', name: 'Badges', component: Badges },
  { path: '/notifications/modals', name: 'Modals', component: Modals },
  { path: '/notifications/toasts', name: 'Toasts', component: Toasts },
  { path: '/widgets', name: 'Widgets', component: Widgets },
]

export default routes
