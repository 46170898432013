import React, { useEffect, useState } from 'react'
import {
  CAvatar,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormCheck,
  CHeader,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import axios from 'axios'
import DefaultLayout from 'src/layout/DefaultLayout'
import { URL__API } from 'src/axios'
import avatar from '../../../assets/images/avatars/Avatars.png'
import Loading from 'src/components/Loading'
import Paginated from 'src/components/paginated/Paginated'
import { Link } from 'react-router-dom'
import NoSignal from '../nodata/NoSignal'

function Signal() {
  const [signals, setSignals] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchSignals = async () => {
    const res = await axios.get(`${URL__API}/user/signals`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    if ([200, 201].includes(res.status)) {
      setLoading(false)
      setSignals(res.data)
    }
  }

  useEffect(() => {
    fetchSignals()
  }, [])

  if (loading) {
    return (
      <DefaultLayout>
        <Loading />
      </DefaultLayout>
    )
  }

  const handleShowJob = async (id) => {
    await axios.patch(`${URL__API}/user/${id}/treaty`, null, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    fetchSignals()
  }
  return (
    <DefaultLayout>
      {signals.length === 0 ? (
        <NoSignal msg="des signalements" />
      ) : (
        <>
          <Paginated items={signals}>
            {(paginatedVariables) => (
              <CCol xs>
                <CCard className="mb-4">
                  <CHeader className="mr-4">
                    <CCardHeader>Signals</CCardHeader>
                  </CHeader>
                  <CCardBody>
                    <br />
                    <CTable align="middle" className="mb-0 border" hover responsive>
                      <CTableHead color="light">
                        <CTableRow>
                          <CTableHeaderCell>Signaling</CTableHeaderCell>
                          <CTableHeaderCell>Target</CTableHeaderCell>
                          <CTableHeaderCell>Informations</CTableHeaderCell>
                          <CTableHeaderCell>Traité</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>

                      <CTableBody>
                        {paginatedVariables &&
                          paginatedVariables.map((item, index) => (
                            <CTableRow v-for="item in tableItems" key={index}>
                              <CTableDataCell style={{ cursor: 'pointer' }}>
                                <CTableDataCell className="d-flex">
                                  <div
                                    style={{ width: '4rem', height: '4rem', textAlign: 'center' }}
                                  >
                                    <img
                                      alt="avatar"
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '50%',
                                      }}
                                      src={
                                        item?.signaling?.avatar ? item?.signaling?.avatar : avatar
                                      }
                                    />
                                  </div>

                                  <div className="d-flex flex-xl-column py-2 px-3">
                                    {item?.signaling?.firstName + ' ' + item?.signaling?.lastname}
                                  </div>
                                </CTableDataCell>
                              </CTableDataCell>
                              <CTableDataCell>
                                <CTableDataCell className="d-flex">
                                  <div
                                    style={{ width: '4rem', height: '4rem', textAlign: 'center' }}
                                  >
                                    <img
                                      alt="avatar"
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '50%',
                                      }}
                                      src={item?.target?.avatar ? item?.target?.avatar : avatar}
                                    />
                                  </div>

                                  <div className="d-flex flex-xl-column py-2 px-3">
                                    {item?.target?.firstName + ' ' + item?.target?.lastname}
                                  </div>
                                </CTableDataCell>
                              </CTableDataCell>
                              <CTableDataCell>
                                <Link to={`${item?.id}/signal`}>Plus d informations</Link>
                              </CTableDataCell>

                              <CTableDataCell>
                                <CFormCheck
                                  style={{
                                    padding: '9px',
                                    margin: '11px 12px',
                                  }}
                                  onChange={() => handleShowJob(item?.id)}
                                  id="flexCheckChecked"
                                  checked={item?.isDone}
                                />
                              </CTableDataCell>
                            </CTableRow>
                          ))}
                      </CTableBody>
                    </CTable>
                  </CCardBody>
                </CCard>
              </CCol>
            )}
          </Paginated>
        </>
      )}
    </DefaultLayout>
  )
}

export default Signal
