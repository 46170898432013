import { cilQrCode } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import React from 'react'
import { useHistory } from 'react-router-dom'

function Code() {
  let { location } = useHistory()

  return (
    <CIcon
      width="25"
      height="32"
      style={{
        marginRight: '18px',
        marginLeft: '5px',
        color: `${location.pathname === '/codes' ? '#2ACC96' : 'white'}`,
      }}
      icon={cilQrCode}
    />
  )
}

export default Code
