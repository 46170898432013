import React from 'react'
import PropTypes from 'prop-types'
import { CCol, CContainer, CRow } from '@coreui/react'

function NoSignal({ msg }) {
  return (
    <div
      style={{
        maxWidth: '1440px',
        height: '700px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
      }}
    >
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <div className="text-center">
              <h4 className="pt-3">Oops!</h4>
              <p className="text-center">{`Il n'y a pas encore ${msg}`}</p>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

NoSignal.propTypes = {
  msg: PropTypes.string,
}

export default NoSignal
