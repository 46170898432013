import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CHeader,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import axios from 'axios'
import DefaultLayout from 'src/layout/DefaultLayout'
import { URL__API } from 'src/axios'
import avatar from '../../../assets/images/avatars/Avatars.png'
import Loading from 'src/components/Loading'
import Paginated from 'src/components/paginated/Paginated'
import { Link } from 'react-router-dom'
import NoSignal from '../nodata/NoSignal'

function Message() {
  const [chats, setChats] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchChats = async () => {
    const res = await axios.get(`${URL__API}/messages/all`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    if ([200, 201].includes(res.status)) {
      setLoading(false)
      setChats(res.data)
    }
    if (res.data.length === 0) {
      return <NoSignal msg="des messages" />
    }
    setChats(
      res.data.filter((d, i) => {
        const index = res.data.findIndex(
          (x) => x.sender.id === d.sender.id || x.receiver.id === d.sender.id,
        )
        return index === i
      }),
    )
  }

  useEffect(() => {
    fetchChats()
  }, [])

  if (loading) {
    return (
      <DefaultLayout>
        <Loading />
      </DefaultLayout>
    )
  }

  return (
    <DefaultLayout>
      {chats.length === 0 ? (
        <NoSignal msg="des messages" />
      ) : (
        <>
          <Paginated items={chats}>
            {(paginatedVariables) => (
              <CCol xs>
                <CCard className="mb-4">
                  <CHeader className="mr-4">
                    <CCardHeader>Messages</CCardHeader>
                  </CHeader>
                  <CCardBody>
                    <br />
                    <CTable align="middle" className="mb-0 border" hover responsive>
                      <CTableHead color="light">
                        <CTableRow>
                          <CTableHeaderCell>Expéditeur</CTableHeaderCell>
                          <CTableHeaderCell>Receveur</CTableHeaderCell>
                          <CTableHeaderCell>Les messages</CTableHeaderCell>
                          <CTableHeaderCell></CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>

                      <CTableBody>
                        {paginatedVariables &&
                          paginatedVariables.map((item, index) => (
                            <CTableRow v-for="item in tableItems" key={index}>
                              <CTableDataCell style={{ cursor: 'pointer' }}>
                                <CTableDataCell className="d-flex">
                                  <div
                                    style={{ width: '4rem', height: '4rem', textAlign: 'center' }}
                                  >
                                    <img
                                      alt="avatar"
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '50%',
                                      }}
                                      src={item.sender.avatar ? item.sender.avatar : avatar}
                                    />
                                  </div>

                                  <div className="d-flex flex-xl-column py-2 px-3">
                                    {item.sender.firstName + ' ' + item.sender.lastname}

                                    <span>{item.sender.role.name}</span>
                                  </div>
                                </CTableDataCell>
                              </CTableDataCell>
                              <CTableDataCell>
                                <CTableDataCell className="d-flex">
                                  <div
                                    style={{ width: '4rem', height: '4rem', textAlign: 'center' }}
                                  >
                                    <img
                                      alt="avatar"
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '50%',
                                      }}
                                      src={item.receiver.avatar ? item.receiver.avatar : avatar}
                                    />
                                  </div>

                                  <div className="d-flex flex-xl-column py-2 px-3">
                                    {item.receiver.firstName + ' ' + item.receiver.lastname}

                                    <span>{item.receiver.role.name}</span>
                                  </div>
                                </CTableDataCell>
                              </CTableDataCell>

                              <CTableDataCell>
                                <Link to={`${item?.id}/chat/${item.sender.id}/${item.receiver.id}`}>
                                  Afficher les messages
                                </Link>
                              </CTableDataCell>
                            </CTableRow>
                          ))}
                      </CTableBody>
                    </CTable>
                  </CCardBody>
                </CCard>
              </CCol>
            )}
          </Paginated>
        </>
      )}
    </DefaultLayout>
  )
}

export default Message
