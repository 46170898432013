import React, { useEffect, useState } from 'react'
import DefaultLayout from 'src/layout/DefaultLayout'
import { PropTypes } from 'prop-types'
import axios from 'axios'
import { URL__API } from 'src/axios'
import Loading from 'src/components/Loading'
import * as Yup from 'yup'
import './city.css'
import { Formik } from 'formik'
import { CButton, CCol, CForm, CFormInput, CFormLabel, CRow } from '@coreui/react'

function UpdateCity({ history, match }) {
  const [city, setCity] = useState(null)

  useEffect(() => {
    const fetchCity = async () => {
      const res = await axios.get(`${URL__API}/cities/${match.params.id}`)

      setCity(res.data)
    }

    fetchCity()
  }, [match.params.id])

  const update = async ({ langSymbol = 'ar', translation }) => {
    try {
      const res = await axios.put(
        `${URL__API}/cities/${match.params.id}/translate`,
        {
          langSymbol,
          translation,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      )
      if (res.status === 200) {
        history.push('/cities')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const validationSchema = Yup.object().shape({
    label: Yup.string().required('Veuillez renseigner ce champ'),
    translation: Yup.string().required('Veuillez renseigner ce champ'),
  })

  return (
    <DefaultLayout>
      {!city ? (
        <Loading />
      ) : (
        <div className="">
          <Formik validationSchema={validationSchema} initialValues={city} onSubmit={update}>
            {({ values, touched, errors, handleChange, handleSubmit, setFieldValue }) => (
              <CForm className="city" onSubmit={handleSubmit}>
                <CRow className="city_field">
                  <CCol xs>
                    <CFormLabel>Nom</CFormLabel>
                    <CFormInput
                      placeholder="ville"
                      type="text"
                      name="label"
                      value={values.label}
                      onChange={handleChange}
                    />
                    {errors.label && touched.label && (
                      <p style={{ color: 'red' }}>{errors.label}</p>
                    )}
                  </CCol>
                </CRow>

                <CRow className="city_field">
                  <CCol xs>
                    <CFormLabel>Traduction</CFormLabel>
                    <CFormInput
                      placeholder="Traduction"
                      type="text"
                      name="translation"
                      value={values?.translations['ar'] && values?.translations['ar']}
                      onChange={handleChange}
                    />
                    {errors.translation && touched.translation && (
                      <p style={{ color: 'red' }}>{errors.translation}</p>
                    )}
                  </CCol>
                </CRow>

                <CRow className="city_field">
                  <CCol xs>
                    <CButton type="submit" className="w-100 py-2 city_btn">
                      Modifier
                    </CButton>
                  </CCol>
                </CRow>
                {/* {fail && (
                  <CAlert
                    style={{
                      marginTop: '10px',
                    }}
                    color="danger"
                  >
                    Ce metrier existe déjà
                  </CAlert>
                )} */}
              </CForm>
            )}
          </Formik>
        </div>
      )}
    </DefaultLayout>
  )
}

UpdateCity.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
}

export default UpdateCity
