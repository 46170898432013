import React from 'react'
import { useHistory } from 'react-router-dom'

function Metier() {
  let { location } = useHistory()

  return (
    <div
      style={{
        color: `${location.pathname === '/métier' ? '#2ACC96' : 'white'}`,
      }}
    >
      Métier
    </div>
  )
}

export default Metier
