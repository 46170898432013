/* eslint-disable react-hooks/exhaustive-deps */
import { CButton, CCard, CCardBody, CCardText, CCardTitle } from '@coreui/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { URL__API } from 'src/axios'
import DefaultLayout from 'src/layout/DefaultLayout'
import './dashboard.css'

const Dashboard = () => {
  const [users, setUsers] = useState([])
  const [offers, setOffers] = useState([])
  const [bricoleurs, setBricoleurs] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const clients = await axios.get(`${URL__API}/user/all/users`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      setUsers(clients.data)

      const bricoleurs = await axios.get(`${URL__API}/user/all/bricoleurs`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      setBricoleurs(bricoleurs.data)

      const res = await axios.get(`${URL__API}/offers`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      setOffers(res.data)
    }
    fetchData()
  }, [])

  return (
    <DefaultLayout>
      <div className="dashboard">
        <Link className="dashboard__link" to={`/users`}>
          <CCard className="dashboard__card">
            <CCardBody>
              <CCardTitle>Nombre d&apos;utilisateurs</CCardTitle>
              <hr />
              <CCardText className="text-center dashboard__size">{users.length}</CCardText>
            </CCardBody>
          </CCard>
        </Link>
        <Link className="dashboard__link" to={`/bricoleurs`}>
          <CCard className="dashboard__card">
            <CCardBody>
              <CCardTitle>Nombre de bricoleurs</CCardTitle>
              <hr />
              <CCardText className="text-center dashboard__size">{bricoleurs.length}</CCardText>
            </CCardBody>
          </CCard>
        </Link>
      </div>
      <div className="dashboard__div">
        <Link className="dashboard__link" to={`/demandes`}>
          <CCard className="dashboard__card">
            <CCardBody>
              <CCardTitle>Nombre de demandes</CCardTitle>
              <hr />
              <CCardText className="text-center dashboard__size">{offers.length}</CCardText>
            </CCardBody>
          </CCard>
        </Link>
      </div>
    </DefaultLayout>
  )
}

export default Dashboard
