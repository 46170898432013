import React, { useEffect, useState } from 'react'
import DefaultLayout from 'src/layout/DefaultLayout'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormCheck,
  CHeader,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import Loading from 'src/components/Loading'
import axios from 'axios'
import { URL__API } from 'src/axios'
import { Link } from 'react-router-dom'
import CIcon from '@coreui/icons-react'
import { cilExternalLink, cilPen, cilPlus, cilX } from '@coreui/icons'
import Paginated from 'src/components/paginated/Paginated'
import * as toastr from 'toastr'
import 'toastr/build/toastr.css'

function Jobs() {
  const [jobs, setJobs] = useState([])
  const [visible, setVisible] = useState(false)
  const [jobId, setJobId] = useState('')
  const [loading, setLoading] = useState(true)

  const fetchJobs = async () => {
    const res = await axios.get(`${URL__API}/jobs`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    setLoading(false)

    setJobs(res.data)
  }

  useEffect(() => {
    fetchJobs()
  }, [])

  const handleAccept = async (id) => {
    setVisible(true)
    if (visible === true) {
      await axios.delete(`${URL__API}/jobs/remove/${id}`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      setVisible(false)
      setJobId('')
      fetchJobs()
      toastr.success('Ce métier est supprimé avec succès', {
        positionClass: 'toast-bottom-left',
      })
    }
  }

  const handleShowJob = async (id) => {
    await axios.patch(`${URL__API}/jobs/${id}/status`, null, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    setLoading(true)
    fetchJobs()
  }

  return (
    <DefaultLayout>
      {loading ? (
        <Loading />
      ) : (
        <>
          <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
              <CModalTitle>Suppression</CModalTitle>
            </CModalHeader>
            <CModalBody>Etes-vous sûr de supprimer ce métier ?</CModalBody>
            <CModalFooter>
              <CButton color="secondary" onClick={() => setVisible(false)}>
                Non
              </CButton>
              <CButton color="primary" onClick={() => handleAccept(jobId)}>
                Oui
              </CButton>
            </CModalFooter>
          </CModal>

          <Paginated items={jobs}>
            {(paginatedJobs) => (
              <CCol xs>
                <CCard className="mb-4">
                  <CHeader className="mr-4">
                    <CCardHeader>Métiers</CCardHeader>
                    <Link
                      to="/addjobs"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'white',
                        background: '#2ACC96',
                        border: '#2ACC96',
                        textDecoration: 'none',
                        borderRadius: '20px',
                        margin: '20px',
                        paddingRight: '25px',
                        paddingLeft: '25px',
                        paddingBottom: '16px',
                        paddingTop: '16px',
                      }}
                    >
                      <CIcon icon={cilPlus} size="lg" style={{ marginRight: '16px' }}></CIcon>
                      Ajouter un métier
                    </Link>
                  </CHeader>
                  <CCardBody>
                    <br />
                    <CTable align="middle" className="mb-0 border" hover responsive>
                      <CTableHead color="light">
                        <CTableRow>
                          <CTableHeaderCell>Icons</CTableHeaderCell>
                          <CTableHeaderCell>Métier</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Actions</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>

                      <CTableBody>
                        {paginatedJobs &&
                          paginatedJobs.map((item, index) => (
                            <CTableRow v-for="item in tableItems" key={index}>
                              <CTableDataCell>
                                <div
                                  style={{ width: '4rem', height: '4rem', alignItems: 'center' }}
                                >
                                  <img
                                    alt="avatar"
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                    }}
                                    src={item.avatar}
                                  />
                                </div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <Link
                                  style={{ textDecoration: 'none', marginRight: '5px' }}
                                  to={`/job/${item.id}`}
                                >
                                  {item.name}
                                </Link>
                                <CIcon icon={cilExternalLink} size="sm" />
                              </CTableDataCell>

                              <CTableDataCell className="text-center">
                                <Link to={`/metier/${item.id}`}>
                                  <CIcon
                                    icon={cilPen}
                                    title="Modifie"
                                    style={{
                                      color: '#2ACC96',
                                      border: '2px solid #2ACC96',
                                      borderRadius: '100%',
                                      margin: '9px',
                                      cursor: 'pointer',
                                    }}
                                    size="xl"
                                  />
                                </Link>
                                <CIcon
                                  title="supprimer"
                                  icon={cilX}
                                  style={{
                                    cursor: 'pointer',
                                    color: 'red',
                                    border: '2px solid red',
                                    borderRadius: '100%',
                                    padding: '1px',
                                    margin: '9px',
                                  }}
                                  size="xl"
                                  onClick={() => {
                                    setVisible(true)
                                    setJobId(item.id)
                                  }}
                                />

                                <CFormCheck
                                  style={{
                                    padding: '9px',
                                    margin: '11px 12px',
                                  }}
                                  onChange={() => handleShowJob(item?.id)}
                                  id="flexCheckChecked"
                                  // defaultChecked={item?.activated}
                                  checked={item?.activated}
                                />
                              </CTableDataCell>
                            </CTableRow>
                          ))}
                      </CTableBody>
                    </CTable>
                  </CCardBody>
                </CCard>
              </CCol>
            )}
          </Paginated>
        </>
      )}
    </DefaultLayout>
  )
}

export default Jobs
