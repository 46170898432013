import React, { useEffect, useState } from 'react'
import { CAlert, CButton, CCol, CForm, CFormInput, CFormLabel, CRow } from '@coreui/react'
import axios from 'axios'
import { Formik } from 'formik'
import { URL__API } from 'src/axios'
import DefaultLayout from 'src/layout/DefaultLayout'
import { PropTypes } from 'prop-types'
import * as Yup from 'yup'
import { withRouter } from 'react-router-dom'
import Loading from 'src/components/Loading'

function UpdateJob({ history, match }) {
  const [job, setJob] = useState(null)
  const [fail, setFailValue] = useState(false)
  const [trans, setTrans] = useState('')

  const addTranslation = async (langSymbol, translation) => {
    try {
      const res = await axios.put(
        `${URL__API}/jobs/${match.params.id}/translate`,
        {
          langSymbol,
          translation,
        },

        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
    } catch (e) {
      console.log(e)
    }
  }

  const update = async ({ name, file, translation }) => {
    const formData = new FormData()
    formData.append('job', name)
    formData.append('image', file)
    formData.append('type', 'job')
    try {
      const res = await axios.patch(`${URL__API}/jobs/put-job/${match.params.id}`, formData, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      })

      addTranslation('ar', translation)

      // if (res.status === 200) {
      //   history.push('/métier')
      // }
    } catch (error) {
      if (error) {
        setFailValue(true)
      }
      console.error(error)
    }
  }

  useEffect(() => {
    const fetchOneJob = async () => {
      const res = await axios.get(`${URL__API}/jobs/job/${match.params.id}`)

      setJob(res.data)
    }
    fetchOneJob()
  }, [match.params.id])

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Veuillez renseigner ce champ'),
    translation: Yup.string().required('Veuillez renseigner ce champ'),
    file: Yup.object().nullable(),
  })

  return (
    <DefaultLayout>
      {fail || !job ? (
        <Loading />
      ) : (
        <div className="">
          <Formik validationSchema={validationSchema} initialValues={job} onSubmit={update}>
            {({ values, touched, errors, handleChange, handleSubmit, setFieldValue }) => (
              <CForm className="w-75 mt-5 mx-auto" onSubmit={handleSubmit}>
                <CRow className="mx-5">
                  <CCol xs>
                    <CFormLabel>Nom</CFormLabel>
                    <CFormInput
                      placeholder="job"
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    {errors.job && touched.job && <p style={{ color: 'red' }}>{errors.job}</p>}
                  </CCol>
                </CRow>
                <CRow className="m-5">
                  <CCol xs>
                    <CFormLabel>Icon</CFormLabel>
                    <CFormInput
                      placeholder="image"
                      type="file"
                      name="file"
                      onChange={(e) => setFieldValue('file', e.currentTarget.files[0])}
                    />
                    {errors.file && touched.file && <p style={{ color: 'red' }}>{errors.file}</p>}
                  </CCol>
                </CRow>
                <CRow className="mx-5">
                  <CCol xs>
                    <CFormLabel>Order</CFormLabel>
                    <CFormInput
                      placeholder="order"
                      type="number"
                      name="order"
                      value={values.order}
                      onChange={handleChange}
                    />
                    {errors.job && touched.job && <p style={{ color: 'red' }}>{errors.job}</p>}
                  </CCol>
                </CRow>

                <CRow className="m-5">
                  <CCol xs>
                    <CFormLabel>Traduction</CFormLabel>
                    <CFormInput
                      placeholder="Traduction"
                      type="text"
                      name="translation"
                      // value={!trans ? job?.translations?.ar : trans}
                      value={job?.translations?.ar}
                      // onChange={(e) => {
                      //   if (values?.translations?.ar) {
                      //     setTrans('')
                      //   }
                      //   setTrans(e.target.value)
                      //   handleChange(trans)
                      // }}
                      onChange={handleChange}
                    />
                    {errors.translation && touched.translation && (
                      <p style={{ color: 'red' }}>{errors.translation}</p>
                    )}
                  </CCol>
                </CRow>

                <CRow className="m-5">
                  <CCol xs>
                    <CButton
                      type="submit"
                      style={{
                        color: 'white',
                        background: '#2ACC96',
                        border: '#2ACC96',
                        textDecoration: 'none',
                      }}
                      className="w-100 py-2"
                    >
                      Modifier
                    </CButton>
                  </CCol>
                </CRow>
                {fail && (
                  <CAlert
                    style={{
                      marginTop: '10px',
                    }}
                    color="danger"
                  >
                    Ce metrier existe déjà
                  </CAlert>
                )}
              </CForm>
            )}
          </Formik>
        </div>
      )}
    </DefaultLayout>
  )
}

UpdateJob.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
}

export default withRouter(UpdateJob)
