import React, { useEffect, useState } from 'react'
import {
  CButton,
  CCard,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CRow,
} from '@coreui/react'
import axios from 'axios'
import { URL__API } from 'src/axios'
import DefaultLayout from 'src/layout/DefaultLayout'
import { PropTypes } from 'prop-types'
import Loading from 'src/components/Loading'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import '../css/update.css'
import CIcon from '@coreui/icons-react'
import { cilX } from '@coreui/icons'
import './bricoleur.css'

function Bricoleur({ history, match }) {
  const [jobs, setJobs] = useState([])
  const [images, setImages] = useState([])
  const [bricoleur, setBricoleur] = useState(null)
  const [failed, setFailed] = useState(false)
  const [open, setOpen] = useState(false)
  const [index, setIndex] = useState(0)

  const fetchBricoleur = async () => {
    const res = await axios.get(`${URL__API}/user/profile/${match.params.id}`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })

    if (res.status !== 200 || res.status === 404) {
      setFailed(true)
    }

    setBricoleur(res.data)
    setImages(res.data.cinPic)
  }

  const handleVerification = async () => {
    await axios.put(`${URL__API}/verification/identity/${match.params.id}`, null, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    fetchBricoleur()
  }

  const handleUnVerification = async () => {
    await axios.put(`${URL__API}/verification/un/identity/${match.params.id}`, null, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    fetchBricoleur()
  }

  useEffect(() => {
    try {
      const fetchJobs = async () => {
        const res = await axios.get(`${URL__API}/user/jobs`)

        if (res.status !== 200 || res.status === 404) {
          setFailed(true)
        }
        setJobs(res.data)
      }

      fetchJobs()
      fetchBricoleur()
    } catch (error) {
      setFailed(true)
      console.log(error)
    }
  }, [])

  const deletOnePhoto = async (id, label) => {
    try {
      await axios.post(
        `${URL__API}/user/${id}/photo`,
        { label },
        {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      )
      fetchBricoleur()
    } catch (error) {
      console.error(error)
    }
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Veuillez renseigner ce champ'),
    lastname: Yup.string().required('Veuillez renseigner ce champ'),
    email: Yup.string().email("Format d'email invalide").required('Veuillez renseigner ce champ'),
    phone: Yup.string().required('Veuillez renseigner ce champ'),
    cin: Yup.string().required('Veuillez renseigner ce champ'),
  })

  return (
    <DefaultLayout>
      {failed || !bricoleur ? (
        <Loading />
      ) : (
        <CCard className="w-75 mx-auto bricoleur_container">
          <Formik validationSchema={validationSchema} initialValues={bricoleur}>
            {({ values, touched, errors, handleChange, handleSubmit }) => (
              <CForm className="w-75 mt-5 mx-auto" onSubmit={handleSubmit}>
                <CRow className="mx-5 bricoleur_row">
                  <CCol xs>
                    <CFormLabel>Nom</CFormLabel>
                    <CFormInput
                      placeholder="Nom"
                      type="text"
                      name="firstName"
                      value={values?.firstName}
                      onChange={handleChange}
                    />
                    {errors.firstName && touched.firstName && (
                      <p style={{ color: 'red' }}>{errors.firstName}</p>
                    )}
                  </CCol>
                  <CCol xs>
                    <CFormLabel>Prénom</CFormLabel>
                    <CFormInput
                      placeholder="Prénom"
                      type="text"
                      name="lastname"
                      value={values?.lastname}
                      onChange={handleChange}
                    />
                    {errors.lastnames && touched.lastnames && (
                      <p style={{ color: 'red' }}>{errors.lastnames}</p>
                    )}
                  </CCol>
                </CRow>
                <CRow className="m-5 bricoleur_row">
                  <CCol xs>
                    <CFormLabel>Email</CFormLabel>
                    <CFormInput
                      placeholder="Email"
                      type="email"
                      name="email"
                      value={values?.email}
                      onChange={handleChange}
                    />
                    {errors.email && touched.email && (
                      <p style={{ color: 'red' }}>{errors.email}</p>
                    )}
                  </CCol>
                  <CCol xs>
                    <CFormLabel>Téléphone</CFormLabel>
                    <CFormInput
                      placeholder="Téléphone"
                      type="text"
                      name="phone"
                      value={values?.phone}
                      onChange={handleChange}
                    />
                    {errors.phone && touched.phone && (
                      <p style={{ color: 'red' }}>{errors.phone}</p>
                    )}
                  </CCol>
                </CRow>
                <CRow className="m-5 bricoleur_row">
                  <CCol xs>
                    <CFormLabel>Métier</CFormLabel>
                    <CFormInput
                      placeholder="address"
                      type="text"
                      name="job"
                      value={bricoleur?.job.name}
                      onChange={handleChange}
                    />
                  </CCol>
                  <CCol xs>
                    <CFormLabel>Ville</CFormLabel>
                    <CFormInput
                      placeholder="address"
                      type="text"
                      name="city"
                      value={bricoleur?.city && bricoleur?.city.label}
                      onChange={handleChange}
                    />
                  </CCol>
                </CRow>
                <CRow className="m-5 bricoleur_row">
                  <CCol xs>
                    <CFormLabel>Pays</CFormLabel>
                    <CFormInput
                      placeholder="address"
                      type="text"
                      name="country"
                      value={bricoleur?.country}
                      onChange={handleChange}
                    />
                  </CCol>
                  <CCol xs>
                    <CFormLabel>Rôle</CFormLabel>
                    <CFormInput
                      placeholder="address"
                      type="text"
                      name="roles"
                      value={bricoleur?.role.name}
                      onChange={handleChange}
                    />
                  </CCol>
                </CRow>
                <CRow className="m-5 bricoleur_row">
                  <CCol xs>
                    <CFormCheck
                      id="flexCheckChecked"
                      label="Auto-Entrepreneur"
                      defaultChecked={bricoleur?.isAutoEntrepreneur}
                    />
                  </CCol>
                  <CCol xs>
                    <CFormCheck
                      id="flexCheckChecked"
                      label="E-mail confirmé"
                      // style={{ backgroundColor: 'green', border: '1px solid green' }}
                      defaultChecked={bricoleur?.isEmailConfirmed}
                    />
                  </CCol>
                  <CCol xs>
                    <CFormCheck
                      id="flexCheckChecked"
                      label="Téléphone vérifié"
                      defaultChecked={bricoleur?.isPhoneVerified}
                    />
                  </CCol>
                </CRow>
                <CRow className="m-5 bricoleur_row">
                  {values.cin && (
                    <CCol xs>
                      {<CFormLabel>CIN</CFormLabel>}
                      <CFormInput
                        placeholder="cin"
                        type="text"
                        name="cin"
                        value={values.cin}
                        onChange={handleChange}
                      />
                      {errors.cin && touched.cin && <p style={{ color: 'red' }}>{errors.cin}</p>}
                    </CCol>
                  )}
                  {values.autoEntrepreneur && (
                    <CCol xs>
                      <CFormLabel>Auto Entrepreneur</CFormLabel>
                      <CFormInput
                        placeholder="autoEntrepreneur"
                        type="text"
                        name="autoEntrepreneur"
                        value={values?.autoEntrepreneur}
                        // onChange={handleChange}
                      />
                    </CCol>
                  )}
                  {values.codeSociete && (
                    <CCol xs>
                      <CFormLabel>Auto Entrepreneur</CFormLabel>
                      <CFormInput
                        placeholder="codeSociete"
                        type="text"
                        name="codeSociete"
                        value={values?.codeSociete}
                        // onChange={handleChange}
                      />
                    </CCol>
                  )}
                </CRow>
                <CRow className="my-5 mx-4 bricoleur_row">
                  <CCol xs className="d-flex justify-content-around">
                    {values.cinPic &&
                      !values.cinPic.includes('[]') &&
                      values.cinPic.map((img, i) => (
                        <div key={img} className="container__pos">
                          <img
                            alt="cin"
                            className="mx-4"
                            width={275}
                            style={{ objectFit: 'fill', cursor: 'pointer' }}
                            height={200}
                            onClick={() => {
                              setIndex(i)
                              setOpen(true)
                            }}
                            onBlur={() => setOpen(false)}
                            src={img}
                          />
                          <CIcon
                            title="supprimer"
                            icon={cilX}
                            className="top"
                            size="xl"
                            onClick={() => deletOnePhoto(bricoleur.id, img)}
                          />
                        </div>
                      ))}
                    {open && (
                      <Lightbox
                        mainSrc={images[index]}
                        nextSrc={images[(index + 1) % images.length]}
                        prevSrc={images[(index + images.length - 1) % images.length]}
                        onCloseRequest={() => setOpen(false)}
                        onMovePrevRequest={() =>
                          setIndex((index + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                          setIndex((index + images.length + 1) % images.length)
                        }
                      />
                    )}
                  </CCol>
                </CRow>

                {/* {!bricoleur.isIdentityVerified && ( */}
                <CRow className="m-5 bricoleur_row">
                  <CCol xs>
                    <CButton
                      type="submit"
                      style={{
                        color: 'white',
                        background: '#2ACC96',
                        border: '#2ACC96',
                        textDecoration: 'none',
                      }}
                      disabled={bricoleur.isIdentityVerified}
                      className="w-100 py-2"
                      onClick={() => handleVerification()}
                    >
                      {bricoleur.isIdentityVerified
                        ? 'Ce bricoleur est vérifié'
                        : 'Vérifier ce bricoleur'}
                    </CButton>
                  </CCol>
                </CRow>
                {/* )} */}

                {bricoleur.isIdentityVerified && (
                  <CRow className="m-5 bricoleur_row">
                    <CCol xs>
                      <CButton
                        type="submit"
                        style={{
                          color: 'white',
                          background: 'red',
                          border: '#2ACC96',
                          textDecoration: 'none',
                        }}
                        className="w-100 py-2"
                        onClick={() => handleUnVerification()}
                      >
                        Annuler la verification
                      </CButton>
                    </CCol>
                  </CRow>
                )}
              </CForm>
            )}
          </Formik>
        </CCard>
      )}
    </DefaultLayout>
  )
}

Bricoleur.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
}

export default Bricoleur
